import ArticleIcon from '@mui/icons-material/Article';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import { LinearProgress } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useGetAllFleetData } from '../../db/useGetAllFleetData';
import { useGetAllTransportRequisitionData } from '../../db/useGetAllTransportRequisitionData';
import { addresses, setAddresses } from '../../store/addressSlice';
// import { drivers, vehicles } from '../../store/fleetSlice';
import { allUsersRights, setAllUsersRights, transportRequisitionRights } from '../../store/transportRequisitionSlice';
import { clearUserDetails, userDetails, users } from '../../store/userSlice';
import Addresses from '../addresses/Addresses';
// import FleetPage from '../fleet/FleetPage';
import Settings from '../settings/Settings';
// import ApprovalPage from '../waitingForApproval/ApprovalPage';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from "firebase/auth";
import { auth } from "../../config/fire";
import { useGetAllFleetData } from '../../db/useGetAllFleetData';
import { drivers, vehicles } from '../../store/fleetSlice';
import { parts } from '../../store/partSlice';
import FleetPage from '../fleet/FleetPage';
import TransportRequisitions from '../transportRequisitions/main/TransportRequisitions';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { addressURL, getRequest, transportRequestURL } from '../../db/apiHandler';
import ImportExportExcel from '../importTracker/ImportExportExcel';
import UsersRightsPage from '../usersRightsPage/UsersRightsPage';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {

  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [isSettingsPage, setIsSettingsPage] = React.useState(false);
  const [isTrackerPage, setIsTrackerPage] = React.useState(true);
  const [isImportPage, setIsImportPage] = React.useState(false);
  const [isUserRightsPage, setIsUserRightsPage] = React.useState(false);
  const [isApprovalPage, setIsApprovalPage] = React.useState(false);
  const [isFleetPage, setIsFleetPage] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // const s_requisition = useSelector(requisitions);
  const s_user_rights = useSelector(transportRequisitionRights);
  const s_userDetails = useSelector(userDetails);
  const s_drivers = useSelector(drivers);
  const s_vehicles = useSelector(vehicles);
  const s_users = useSelector(users);
  const s_parts = useSelector(parts);
  const s_allUserRights = useSelector(allUsersRights);
  // const s_unapprovedRequests = useSelector(unapprovedRequests);

  const theme = useTheme();
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [canFetch, setCanFetch] = useState(false);
  const [canFetchFleet, setCanFetchFleet] = useState(false);
  const re = useGetAllTransportRequisitionData(canFetch);

  const result = useGetAllFleetData(canFetchFleet);

  // useEffect(() => {
  //   let interval = setInterval(async () => {
  //     if (s_user_rights && !s_user_rights.isContractManager) {
  //       const response = await getUnapprovedJobs()
  //         .catch((error) => {
  //           console.error('error', error);
  //         });
  //       dispatch(setUnapprovedRequests(response.response));

  //     }
  //   }, 30000);
  //   return () => {
  //     clearInterval(interval);
  //   };

  // }, []);


  useEffect(() => {
    if (isTrackerPage) {
      if (s_users && s_parts) {
        setIsDataLoaded(true);
        setIsDataLoading(false);
      } else {
        setIsDataLoaded(false);
      }
    }
  }, [isTrackerPage, s_users, s_parts])


  useEffect(() => {
    if (s_drivers && s_vehicles) {
      setIsDataLoaded(true);
      setIsDataLoading(false);
    } else {
      setIsDataLoaded(false);
    }
  }, [s_drivers, s_vehicles])

  useEffect(() => {
    if (s_users && s_allUserRights) {
      setIsDataLoaded(true);
      setIsDataLoading(false);
    } else {
      setIsDataLoaded(false);
    }
  }, [s_users, s_allUserRights])


  // useEffect(() => {
  //   if (result.data) {
  //     // dispatch(setRequisitions(re.data));
  //   }
  //   if (s_drivers && s_vehicles) {
  //     setIsDataLoaded(true);
  //     setIsDataLoading(false);
  //   } else {
  //     setIsDataLoaded(false);
  //   }
  // }, [result])


  const getRequestsDataFromAPI = async () => {


    if (!canFetch) {
      setIsDataLoading(true);
      setIsDataLoaded(false);
      setCanFetch(true);
    }
    setIsSettingsPage(false);
    setIsApprovalPage(false);
    setIsFleetPage(false);
    setIsImportPage(false);
    setIsUserRightsPage(false);
    setIsTrackerPage(true);
    // if (s_requisition === null) {
    //   setIsDataLoaded(false);
    //   setIsDataLoading(true);
    //   let start = new Date();
    //   start.setHours(0, 0, 0);
    //   let end = start;
    //   end.setHours(23, 59, 59);
    //   const filterBy = {
    //     "showOnly": [
    //       "Collection",
    //       "Delivery",
    //       "SSL Collection",
    //       "TSL Collection"
    //     ],
    //     "startDate": start,
    //     "endDate": "2022-09-30 23:59:59",
    //     "filterBy": [
    //       "Customer name",
    //       "Project name",
    //       "Address",
    //       "Job No",
    //       "Despatch Note No",
    //       "Project Main Contractor",
    //       "Created by"
    //     ],
    //     "searchField": "27"
    //   }
    //   const response = await getTransportRequisitions()
    //     .catch((error) => {
    //       console.error('error', error);
    //     });

    //   setIsTrackerPage(true);
    //   dispatch(setAddresses(response.response));
    // } else {

    //   setIsTrackerPage(true);
    // }
  }

  const getFleetDataFromAPI = async () => {
    if (!canFetchFleet) {
      setIsDataLoading(true);
      setIsDataLoaded(false);
      setCanFetchFleet(true);
    }
    setIsTrackerPage(false);
    setIsSettingsPage(false);
    setIsApprovalPage(false);
    setIsImportPage(false);
    setIsUserRightsPage(false);
    setIsFleetPage(true);
  }

  const loadSettingsPage = () => {
    setIsTrackerPage(false);
    setIsImportPage(false);
    setIsApprovalPage(false);
    setIsFleetPage(false);
    setIsUserRightsPage(false);
    setIsSettingsPage(true);
  }

  const loadImportPage = () => {
    setIsTrackerPage(false);
    setIsSettingsPage(false);
    setIsApprovalPage(false);
    setIsFleetPage(false);
    setIsUserRightsPage(false);
    setIsImportPage(true);
  }

  const loadUserRightsPage = async () => {
    setIsTrackerPage(false);
    setIsSettingsPage(false);
    setIsApprovalPage(false);
    setIsFleetPage(false);
    setIsImportPage(false);

    if (s_allUserRights === null) {
      setIsDataLoaded(false);
      setIsDataLoading(true);
      const response = await getRequest(`${transportRequestURL}/appRights`)
        .catch((error) => {
          console.error('error', error);
        });
      setIsUserRightsPage(true);
      dispatch(setAllUsersRights(response.response));
    } else {
      setIsUserRightsPage(true);
    }
  }


  // const loadApprovalPage = async () => {
  //   if (s_unapprovedRequests === null) {
  //     setIsDataLoaded(false);
  //     setIsDataLoading(true);
  //     const response = await getUnapprovedJobs()
  //       .catch((error) => {
  //         console.error('error', error);
  //       });
  //     dispatch(setUnapprovedRequests(response.response));
  //   }
  //   setIsTrackerPage(false);
  //   setIsAddressesPage(false);
  //   setIsSettingsPage(false);
  //   setIsImportPage(false);
  //   setIsFleetPage(false);
  //   setIsApprovalPage(true);
  // }

  const handleLogout = async () => {

    // Log Out
    await signOut(auth);
    dispatch(clearUserDetails())

  }

  return (
    <Box sx={{ display: 'flex'/* , justifyContent: 'end' */ }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}/*  sx={{ width: open ? '80%' : '100%'}} */>
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Transport Tracker
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} /* PaperProps={{ style: { width: open ? '20%' : '4%' } }}  */>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {s_user_rights && (s_user_rights.isTransportAdmin || s_user_rights.isContractManager || s_user_rights.isAdmin) ?
            <ListItemButton
              key="TransportRequests"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={getRequestsDataFromAPI}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary='Transport Forms' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
            : null}
          {/* {s_user_rights && (s_user_rights.isAdmin || s_user_rights.isTransportAdmin) ?
            <ListItemButton
              key="approval"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={loadApprovalPage}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Badge badgeContent={s_unapprovedRequests && s_unapprovedRequests.filter(el => el.approvedBy === null && el.isApproved === 0).length} color="primary">

                  <AddTaskIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary='Waiting for Approval' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton> : null} */}
          {s_user_rights && (s_user_rights.isFleetAdmin || s_user_rights.isAdmin) ?
            <ListItemButton
              key="Fleet"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={getFleetDataFromAPI}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DirectionsCarIcon />
              </ListItemIcon>
              <ListItemText primary='Fleet' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton> : null}
          {s_user_rights && (s_user_rights.isTransportAdmin || s_user_rights.isContractManager || s_user_rights.isAdmin) ?
            <ListItemButton
              key="Settings"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={loadSettingsPage}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Settings' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
            : null}

          {s_user_rights && s_user_rights.isAdmin ?
            <ListItemButton
              key="usersRights"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={loadUserRightsPage}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary='User Rights' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton> : null
          }

          {/* {s_user_rights && (s_user_rights.isTransportAdmin || s_user_rights.isAdmin) ? */}
          {s_user_rights && s_userDetails && (s_userDetails.uid === 'rvP7dU1D5jVDUaUz2PruJN0bcsm2' || s_userDetails.uid === 'mw2MfHupRCbWynwHaDDIEllsx952') ?
            <ListItemButton
              key="import"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={loadImportPage}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary='Import / Export' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton> : null
           }
          <Divider />
          <ListItemButton
            key="logout"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={handleLogout}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Log Out' sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'auto'/* , maxWidth: open ? '80%' : '100%' */ }} >
        <DrawerHeader />
        <Box sx={{ bgcolor: "background.default", color: "text.primary" }}>
          {props.children}

          {isDataLoaded && isTrackerPage ?
            ((s_user_rights && (s_user_rights.isAdmin || s_user_rights.isTransportAdmin || s_user_rights.isContractManager)) ?
              <TransportRequisitions></TransportRequisitions>
              :
              <Box>{"You have no permission to access this page."}</Box>) :
            isDataLoading ? <LinearProgress /> : null}
          {isDataLoaded && isFleetPage ? ((s_user_rights && (s_user_rights.isFleetAdmin || s_user_rights.isAdmin)) ? <FleetPage /> : <Box>{"You have no permission to access this page."}</Box>) :
            isDataLoading ? <LinearProgress /> : null}
          {/* {isDataLoaded && isApprovalPage ? s_user_rights && (s_user_rights.isAdmin || s_user_rights.isTransportAdmin) ? <ApprovalPage /> : <Box>{"You have no permission to access this page."}</Box> : isDataLoading ? <LinearProgress /> : null} */}
          {isSettingsPage && (s_user_rights.isAdmin || s_user_rights.isContractManager || s_user_rights.isTransportAdmin) ? <Settings /> : null}
          {isDataLoaded && isUserRightsPage ?
            ((s_user_rights && s_user_rights.isAdmin) ?
              <UsersRightsPage /> :
              <Box>{"You have no permission to access this page."}</Box>) :
            isDataLoading ? <LinearProgress /> : null}
          {isImportPage ? (s_user_rights.isAdmin || s_user_rights.isTransportAdmin) ? <ImportExportExcel /> : <Box>{"You have no permission to access this page."}</Box> : null}
        </Box>
      </Box>
    </Box>
  );
}

