//SET IF DATABASE IS IN DEV MODE

import { getAuth } from "firebase/auth";


import { store } from "../store";
import { devMode } from "./devMode";
import { createActivityLog } from "../firebaseActivityLog/CreateFirebaseStructure";
import moment from "moment";

export const devURL = "http://localhost:8080/api";
export const url = "https://dev.c4464976.myzen.co.uk/api";

export const apiURL = devMode ? devURL : url;
export const edcURL = `${apiURL}/edc`;
export const foURL = `${apiURL}/fo`;
export const edcUser = `${apiURL}/user`;
export const edcBomURL = `${edcURL}/boms`;
export const edcProjectURL = `${edcURL}/project`;
export const edcMessageURL = `${edcURL}/message`;
export const edcSevURL = `${edcURL}/sev`;
export const transportRequestURL = `${apiURL}/transportRequest`;
export const addressURL = `${apiURL}/addresses`;
export const fleetURL = `${apiURL}/transportFleet`;

export const erpSystemURL = devMode ? "https://apps.fitoutuk.com/foukAPIs/fitoutuk_erp/ERPSystem" : "https://apps.fitoutuk.com/foukAPIs/fitoutuk_erp/ERPSystem"



export const route_ValidationReport = () => { return `${edcURL}/validationreport` }
export const route_ProjectsAdmin = () => { return `${edcURL}/projectsAdmin` }
export const route_addressesTesco = () => { return `${apiURL}/address/address/customer/TESC001` }
export const route_usersProjects = (uid) => { return `${edcURL}/userprojects/${uid}` }

// console.error(store.getState().appInfo.appVersion)

//REQUEST PROPS - NO AUTH
const commonProps = { mode: 'cors', cache: 'no-cache', credentials: 'same-origin', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } }
const getProps = (method) => {
    let commonPropsCopy = {...commonProps};
    commonPropsCopy.headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Appversion': store.getState().appInfo.appVersion }
    return new Promise((resolve, reject) => {
        let props
        switch (method) {
            case 'GET': props = { method, ...commonPropsCopy, redirect: 'follow', referrerPolicy: 'no-referrer' };
            default: props = { method, ...commonPropsCopy }
        }
        resolve(props)
    })

}

//REQUEST PROPS - FIREBASE AUTH/JWT
export const getAuthProps = async (method) => {
    return new Promise(async (res, rej) => {
        const jwt = await getAuth().currentUser.getIdToken();
        let headers = new Headers()
        headers.append('Authorization', jwt);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        var obj = { method, headers, mode: 'cors', cache: 'no-cache', credentials: 'same-origin' }
        res(obj)
    })
}

const getRequestProps = async (method, useAuth) => {
    return new Promise(async (resolve, reject) => {
        let props;
        if (useAuth) {
            props = await getAuthProps(method)
        } else {
            props = await getProps(method)
        }
        resolve(props)
    })
}

export const getRequest = async (url, useAuth = false) => {
    const props = await getRequestProps('GET', useAuth)
    let time1 = performance.now()
    const responce = await fetch(url, { ...props })
    let time2 = performance.now();
    if(time2 - time1 > 5000) {
        sendEmail(url, null, time2 - time1, 'GET');
    }
    return responce.json();
}

export const postRequest = async (url, data, useAuth = false) => {
    const props = await getRequestProps('POST', useAuth)
    let time1 = performance.now()
    const responce = await fetch(url, { ...props, body: JSON.stringify(data) })
    let time2 = performance.now();
    if(time2 - time1 > 5000) {
        sendEmail(url, data, time2 - time1, 'POST');
    }
    return responce.json();

}

export const putRequest = async (url, data, useAuth = false) => {
    const props = await getRequestProps('PUT', useAuth)
    let time1 = performance.now()
    const responce = await fetch(url, { ...props, body: JSON.stringify(data) })
    let time2 = performance.now();
    if(time2 - time1 > 5000) {
        sendEmail(url, null, time2 - time1, 'PUT');
    }
    return responce.json();

}

export const deleteRequest = async (useAuth = false) => {
    const props = await getRequestProps('DELETE', useAuth)
    let time1 = performance.now()
    const responce = await fetch(url, { ...props })    
    let time2 = performance.now();
    if(time2 - time1 > 5000) {
        sendEmail(url, null, time2 - time1, 'DELETE');
    }
    return responce.json();

}




const createResponse = (success, response) => {
    return ({ success, response })
}

export const serverErrResponse = (action) => {
    return ({ success: false, response: `Unknown Error: ${action}` })
}


export const handleAPIResponse = (response) => {
    let result = null
    if (response.success) {
        switch (response.status) {
            case 200: result = createResponse(true, response.response); break;
            case 404: result = createResponse(true, "There was no data found"); break;
            default: result = createResponse(false, `There was and unknown status code (${response.status})`)
                break;
        }
    } else {
        return result = createResponse(false, "There was a server error, Please try again")
    }
    return result
}


export const getAppVersion = async () => {

    const url = `${transportRequestURL}/appVersion`
    const responce = await fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', credentials: 'same-origin', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }, redirect: 'follow', referrerPolicy: 'no-referrer' });
    return responce.json();
}

const sendEmail = async (url, dataObj, timing, type) => {

    createActivityLog(url, type, dataObj ? JSON.stringify(dataObj) : '', timing, getAuth().currentUser.uid, moment().format('YYYY-MM-DD HH:mm:ss'));
    
    // const payload = {
    //     emailAddresses: [{
    //         email: 'iuliamoraru@fitoutuk.com',
    //         name: 'Iulia Moraru'
    //     }],
    //     subject: 'Request Time too long',
    //     title: url,
    //     body: (dataObj ? JSON.stringify(dataObj) : '') + ' ' + timing + ' miliseconds' + '. Request Type: ' + type + '. UID: ' + getAuth().currentUser.uid
    // }

    // // Make the POST request
    // fetch(`https://dev.fitoutuk.com/api/transportRequest/sendGeneralEmail`, {
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(payload),
    // })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.error(data)
    //     })
    //     .catch(error => {
    //         console.error('Error:', error);
    //     });
}

