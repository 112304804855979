import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InfoIcon from '@mui/icons-material/Info';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Snackbar, Stack, TextField } from "@mui/material";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiURL, getRequest, postRequest, putRequest, transportRequestURL } from "../../../db/apiHandler";
import { createPDFfile } from '../../../generatedFiles/DespatchNotePDF';
import { createParagonFile } from "../../../generatedFiles/ParagonCSVfile";
import { addresses, customerAddresses } from '../../../store/addressSlice';
import { parts } from "../../../store/partSlice";
import { addNewestRequisition, deleteSelectedRequisition, exportCSVParagon, requisitions, selectedRequisition, setRequisitions, setSelectedRequisition, transportRequisitionRights } from "../../../store/transportRequisitionSlice";
import { userDetails, users } from "../../../store/userSlice";
import { formatDate } from '../../../utils/DateFormat';
import ChangeStatusDialog from '../dialog/ChangeStatusDialog';
import ChooseJobsParagonDialog from '../dialog/ChooseJobsParagonDialog';
import DeleteRequestDialog from '../dialog/DeleteRequestDialog';
import ParagonErrorDialog from '../dialog/ParagonErrorDialog';
import TransportRequisitionReviewDialog from '../dialog/TransportRequisitionReviewDialog';
import TransportRequisitionsForm from '../dialog/TransportRequisitionsForm';
import DateRangeSelector from "./../../selectors/DateRangeSelector";
import TransportRequisitionsTable from "./TransportRequisitionsTable";
import { setAppVersion } from '../../../store/appSlice';
import { devMode } from '../../../db/devMode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateFirebaseStructure from '../../../firebaseActivityLog/CreateFirebaseStructure';
// import TransportRequisitionsForm from '../../transportRequisition_portals/dialog/TransportRequisitionsForm';

const TransportRequisitions = () => {

    const dispatch = useDispatch();

    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [viewMode, setViewMode] = useState('default');
    const [action, setAction] = useState(null);
    const [showOnly, setShowOnly] = useState(['Delivery', 'Collection', 'SSL Collection', 'TSL Collection']);
    const [requisitionList, setRequisitionList] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openParagonErrorDialog, setOpenParagonErrorDialog] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [palletSpacesUnset, setPalletSpacesUnset] = useState(null);
    const [searchField, setSearchField] = useState('');
    const [filterBy, setFilterBy] = useState(['Consignee Company', 'Project name', 'Address', 'Job No', 'Despatch Note No', 'Project Main Contractor', 'Created by', 'SO Number']);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [paragonList, setParagonList] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [openChooseJobsDialog, setOpenChooseJobsDialog] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [pdfRequisition, setPdfRequisition] = useState(null);
    const [openPdfReviewDialog, setOpenPdfReviewDialog] = useState(false);

    const [isDataLoading, setIsDataLoading] = useState(false);

    const [filter, setFilter] = useState(null);

    const [columns, setColumns] = useState([]);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);


    const s_requisitions = useSelector(requisitions);
    const s_users = useSelector(users);
    const s_parts = useSelector(parts);
    const s_user = useSelector(userDetails);
    const s_user_rights = useSelector(transportRequisitionRights);
    const s_selectedRequest = useSelector(selectedRequisition);
    const s_customerAddresses = useSelector(customerAddresses);


    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [openSubmittedSnackbar, setOpenSubmittedSnackbar] = useState(false);

    const [deleteReason, setDeleteReason] = useState('');
    const [canDelete, setCanDelete] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: moment().set({ hour: 0, minute: 0, second: 0 }),
        endDate: moment().set({ hour: 23, minute: 59, second: 59 })
    });

    const [isLoadButtonContained, setIsLoadButtonContained] = useState(false);

    const options = ['Delivery', 'Collection', 'SSL Collection', 'TSL Collection'];
    const filterOptions = ['Consignee Company', 'Project name', 'Address', 'Job No', 'Despatch Note No', 'Project Main Contractor', 'Created by', 'SO Number'];


    const [allCustomerAddresses, setAllCustomerAddresses] = useState(null);

    useEffect(() => {
        if (!allCustomerAddresses) {
            loadAllCustomerAddresses();
        }
    }, [])


    const loadAllCustomerAddresses = async () => {
        const response = await getRequest(`${apiURL}/addresses/allCustomerAddresses`)
            .catch((error) => {
                console.error('error', error);
            });
        if (response.success) {
            setAllCustomerAddresses(response.response);
        } else {
            if (response.status === 505) {
                dispatch(setAppVersion('CONFLICT'));
            }
        }
    }

    useEffect(() => {
        if (selectedRows.length === 1) {
            setPdfRequisition(selectedRows[0])
        } else {
            setPdfRequisition(null);
        }

    }, [selectedRows])

    useEffect(() => {
        if (s_selectedRequest) {
            setSelectedRows([...selectedRows, s_selectedRequest]);
            setPdfRequisition(s_selectedRequest);
        }
    }, [s_selectedRequest])

    useEffect(() => {
        if (deleteReason === null || deleteReason === '' || (deleteReason && deleteReason.length > 0 && deleteReason.replace(/\s/g, '').length === 0)) {
            setCanDelete(false);
        } else {
            setCanDelete(true);
        }
    }, [deleteReason])

    useEffect(() => {
        let interval = setInterval(async () => {
            if (s_user_rights.autoRefresh) {
                setAutoRefresh(true);
                const response = await getRequest(`${transportRequestURL}/getNewRequests`)
                    .catch((error) => {
                        console.error('error', error);
                    });
                if (response.success) {
                    response.response.forEach(request => {
                        if (request.latestDelivery >= formatDate(new Date(dateRange.startDate)) || request.latestDelivery <= formatDate(new Date(dateRange.endDate))) {

                            dispatch(addNewestRequisition(request));
                        }
                    });
                } else {
                    if (response.status === 505) {
                        dispatch(setAppVersion('CONFLICT'));
                    }
                }
            }
        }, 120000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const filterByDateRange = (startDate, endDate, array, key) => {
        let dateStart = new Date(startDate);
        dateStart.setHours(0);
        dateStart.setMinutes(0);
        dateStart.setMilliseconds(0);
        let dateEnd = new Date(endDate);
        dateEnd.setHours(23);
        dateEnd.setMinutes(59);
        dateEnd.setMilliseconds(59);
        var resultProductData = array.filter(a => {
            var date = new Date(a[key]);
            return (date >= dateStart && date <= dateEnd);
        });
        return (resultProductData)
    }

    const checkDates = (first, second) => {
        let date1 = new Date(first);
        let date2 = new Date(second);
        if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear())
            return 0;
        if (date1 < date2)
            return -1;
        return 1;
    }

    const loadData = async (newStartDate, newEndDate) => {
        setPage(0);
        setIsDataLoading(true);
        let start = new Date(newStartDate);
        start.setHours(0, 0, 0);
        let end = new Date(newEndDate);
        end.setHours(23, 59, 59);
        setFilter({ ...filter, showOnly: showOnly, startDate: formatDate(start), endDate: formatDate(end), filterBy: filterBy, searchField, searchField });
        const response = await postRequest(`${transportRequestURL}/getRequests`, { showOnly: showOnly, startDate: formatDate(start), endDate: formatDate(end), filterBy: filterBy, searchField, searchField })
            .catch((error) => {
                console.error('error', error);
            });
        if (response.success) {
            dispatch(setRequisitions(response.response));
            setIsDataLoading(false);
        } else {
            if (response.status === 505) {
                dispatch(setAppVersion('CONFLICT'));
            }
        }
        setIsLoadButtonContained(false);
        showOnly.sort();
    }
    useEffect(() => {
        if(searchField === '') {
            loadData(dateRange.startDate, dateRange.endDate);
        } else {
            setIsLoadButtonContained(true);
        }
    }, [showOnly, filterBy, searchField])

    useEffect(() => {
        if (s_requisitions) {
            let copyArray = s_requisitions;
            if (s_user_rights.autoRefresh && checkDates(dateRange.startDate, new Date()) === 0) {
                copyArray = s_requisitions.filter(el => moment(el.latestDelivery) >= moment(new Date()));
            }

            setRequisitionList(copyArray);
        }
    }, [s_requisitions])


    const handleCreateRequisition = () => {
        setSelectedRows([])
        setPdfRequisition(null)
        setAction(null)
        setIsAdding(true)
    }

    const handleLoadTransportRequisition = async (act) => {
        if (act !== 'copy') {
            setAction(selectedRows[0].action);
            const response = await getRequest(`${transportRequestURL}/getRequest/${selectedRows[0].id}`)
                .catch((error) => {
                    console.error('error', error)
                    setErrorMessage(error);
                });
            if (response.success) {
                dispatch(setSelectedRequisition(response));
                setIsEditing(true);
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }
        } else {
            setAction(selectedRows[0].action);
            const response = await getRequest(`${transportRequestURL}/getRequest/${selectedRows[0].id}`)
                .catch((error) => {
                    console.error('error', error)
                    setErrorMessage(error);
                });
            if (response.success) {
                let copyReq = {};
                copyReq.response = {};
                copyReq.response.transportRequest = response.response.transportRequest;
                copyReq.response.transportRequest.id = null;
                copyReq.response.transportRequest.userId = s_user.uid;
                copyReq.response.transportRequestEquipmentDetails = response.response.transportRequestEquipmentDetails;
                dispatch(setSelectedRequisition(copyReq));
                setIsEditing(true);
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }
        }
    }


    const handleDeleteTransportRequisition = async () => {
        let response;
        if (selectedRows.length === 1) {
            response = await putRequest(`${transportRequestURL}/deleteRequest`, { id: selectedRows[0].id, deleteReason: deleteReason })
                .catch((error) => {
                    console.error('error', error)
                    setErrorMessage(error);
                });
        } else {
            response = await putRequest(`${transportRequestURL}/deleteMultipleRequests`, { list: selectedRows, deleteReason: deleteReason })
                .catch((error) => {
                    console.error('error', error)
                    setErrorMessage(error);
                });
        }
        if (response.success) {
            selectedRows.forEach(async (el) => {
                let creator = null;
                if (el.userId) {
                    creator = s_users.find(user => user.uid === el.userId);
                }
                let emailAddresses = devMode ? [
                    {
                        "email": s_user.userEmail,
                        "name": s_user.userName
                    }] : [
                    {
                        "email": "transport@fitoutuk.com",
                        "name": "Admin Transport"
                    },
                    {
                        "email": "Transport@prl-logistics.com",
                        "name": "Transport"
                    },
                    {
                        "email": "derekmorley@fitoutuk.com",
                        "name": "Derek Morley"
                    },
                    {
                        "email": s_user.userEmail,
                        "name": s_user.userName
                    }]
                if (creator) emailAddresses.push(
                    {
                        "email": creator.userEmail,
                        "name": creator.userName
                    })
                const data = {
                    emailAddresses: emailAddresses,
                    subject: "Transport Requisition Deleted",
                    title: "This transport requisition has been deleted. ( " + deleteReason + " )",
                    requisition: el,
                    message: "Test message",
                    excelURL: null
                }
                const sendEmailResponse = await postRequest(`${transportRequestURL}/email/validation`, data)
                    .catch((error) => {
                        console.error('error', error)
                        setErrorMessage(error);
                    });
                dispatch(deleteSelectedRequisition(el.id));
            })

        } else {
            if (response.status === 505) {
                dispatch(setAppVersion('CONFLICT'));
            }
        }
        setOpenConfirmationSnackbar(true);

        setIsAdding(false);
        setIsEditing(false);
        setCanDelete(false);
        setDeleteReason('');
        setSelectedRows([]);
    }

    const handleCloseForm = () => {
        setIsAdding(false);
        setIsEditing(false);
        setSelectedRows([]);
        setPdfRequisition(null);
    }

    const handleCloseSnackbar = () => {
        setOpenErrorSnackbar(false);
        setOpenConfirmationSnackbar(false);
        setOpenSubmittedSnackbar(false);
    };

    const handleChangeSelect = (event) => {
        const {
            target: { value },
        } = event;
        setShowOnly([]);
        setShowOnly(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClickOpen = () => {

        setOpenConfirmationDialog(true);
    };

    const handleClose = () => {
        setOpenConfirmationDialog(false);
        setOpenParagonErrorDialog(false);
        setOpenChooseJobsDialog(false);
    };


    const handleDateChange = (dateRange) => {
        setDateRange({ ...dateRange });
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
        loadData(dateRange.startDate, dateRange.endDate);
    }

    const exportParagonFile = async (reqsList) => {
        let createFileError = false;
        const csvURL = await createParagonFile(reqsList, true, s_users, s_customerAddresses)
            .catch(err => {
                createFileError = true;
                console.error('excel err', err)
            })
        if (createFileError) {
            console.error(createFileError)
        } else {
            const exportError = false;
            const exp = await putRequest(`${transportRequestURL}/exportToParagon`, { list: reqsList })
                .catch(err => {
                    exportError = true;
                    console.error('excel err', err)
                })
            if (exportError) {
                if (exp.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            } else {
                dispatch(exportCSVParagon(exp.response));
            }
        }

    }



    const handleDownloadParagonTemplate = async () => {
        // const palletSpacesUnset = requisitionList.filter(el => el.palletSpaces === '' || el.palletSpaces === null);
        // setPalletSpacesUnset(palletSpacesUnset);
        // if (palletSpacesUnset.length > 0) {
        //     setOpenParagonErrorDialog(true);
        // } else {
        let pList = requisitionList.filter(el => el.exportToParagon === 1 || el.exportToParagon === '1');
        setParagonList(pList);
        if (pList.length === 0) {
            exportParagonFile(requisitionList);
        } else {
            setSelectedJobs([]);
            setOpenChooseJobsDialog(true);
        }
        // }
    }

    const handleChangeFilterSelect = (event) => {
        const {
            target: { value },
        } = event;
        setFilterBy([]);
        setFilterBy(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSearch = (e) => {
        setPage(0);
        setSearchField(e.target.value);
    }

    const handleDownloadDespatchNote = (req) => {
        createPDFfile(req);
        setSelectedRows([]);
    }

    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 1);
    const minDateAsMoment = moment(minDate);
    const handleChangeStatus = () => {
        setOpenStatusDialog(true);
    }

    return (
        <Box >
            {!isAdding && !isEditing &&
                <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }} >
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {viewMode !== 'tableOnly' ?
                                <Stack spacing={1} direction="row" >
                                    <FilterAltIcon sx={{ fontSize: 32 }}></FilterAltIcon>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="searchFileld"
                                        name="searchField"
                                        label="Search requisition ..."
                                        type="text"
                                        size="small"
                                        value={searchField}
                                        onChange={handleSearch}
                                        sx={{ width: 250 }}
                                    />
                                    <FormControl sx={{ width: 250 }} size="small">
                                        <InputLabel id="label2">Filter by</InputLabel>
                                        <Select
                                            labelId="label2"
                                            multiple
                                            value={filterBy}
                                            onChange={handleChangeFilterSelect}
                                            input={<OutlinedInput label="Filter by" />}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {filterOptions.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox checked={filterBy.indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!isAdding && !isEditing &&
                                        <FormControl sx={{ width: 250 }} size="small">
                                            <InputLabel id="label">Show only</InputLabel>
                                            <Select
                                                labelId="label"
                                                multiple
                                                value={showOnly}
                                                onChange={handleChangeSelect}
                                                input={<OutlinedInput label="Show only" />}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {options.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        <Checkbox checked={showOnly.indexOf(option) > -1} />
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    <Button variant="outlined" onClick={handleDownloadParagonTemplate} startIcon={<SimCardDownloadIcon />}>Paragon File</Button>
                                </Stack>
                                : null
                            }
                        </Grid>

                        <Grid item xs={4} sx={{ height: viewMode == 'tableOnly' ? '4vh' : '6vh', textAlignLast: 'end' }}>
                            <FormControl>
                                {viewMode !== 'tableOnly' &&
                                    <FormLabel id="viewMode">View Mode</FormLabel>}
                                <RadioGroup
                                    aria-labelledby="viewMode"
                                    defaultValue="default"
                                    name="radio-buttons-group">
                                    <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                                        <FormControlLabel value="default" checked={viewMode === 'default'} control={<Radio onClick={() => setViewMode('default')} />} label="Default" />
                                        {s_user_rights.columnSelection.length > 0 ? <FormControlLabel value="custom" checked={viewMode === 'custom'} control={<Radio onClick={() => setViewMode('custom')} />} label="Custom" /> : null}
                                        <FormControlLabel value="expanded" checked={viewMode === 'expanded'} control={<Radio onClick={() => setViewMode('expanded')} />} label="Expanded" />
                                        <FormControlLabel value="tableOnly" checked={viewMode === 'tableOnly'} control={<Radio onClick={() => setViewMode('tableOnly')} />} label="Tracker only" />
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {viewMode !== 'tableOnly' &&
                        <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                            <Grid item xs={7}>
                                <Stack direction='row' spacing={1} sx={{ display: "flex", flexDirection: "row", alignItems: "right" }}>
                                    <DateRangeSelector handleDateChange={handleDateChange} startDate={startDate} endDate={endDate} maxOneMonth={true} variant={isLoadButtonContained ? 'contained' : 'text'}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack direction='row' spacing={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>

                                    {selectedRows.length <= 1 ? <Button variant='contained' color='primary' onClick={() => setOpenPdfReviewDialog(true)} startIcon={<SimCardDownloadIcon />}>Despatch Note</Button> : null}

                                    {selectedRows.length <= 1 && <Button variant='contained' color='primary' onClick={handleCreateRequisition} size="small" startIcon={<AddCircleIcon />}>Create</Button>}
                                    {selectedRows.length === 1 && (!s_user_rights.isContractManager || (s_user_rights.isContractManager && selectedRows[0].userId === s_user.uid)) && <Button variant='contained' color='primary' onClick={() => handleLoadTransportRequisition('edit')} startIcon={<EditIcon />}>EDIT</Button>}
                                    {selectedRows.length === 1 && (s_user_rights.isContractManager && selectedRows[0].userId !== s_user.uid) && <Button variant='contained' color='primary' onClick={() => handleLoadTransportRequisition('info')} startIcon={<InfoIcon />}>Info</Button>}
                                    {/* {selectedRows.length === 1 && <Button variant='contained' color='primary' onClick={() => handleLoadTransportRequisition('copy')} startIcon={<ContentCopyIcon />}>Copy</Button>} */}
                                    {/* {selectedRows.length > 0 && (s_user_rights.isAdmin || s_user_rights.isTransportAdmin) ? <Button variant='contained' color='success' onClick={() => setOpenStatusDialog(true)} startIcon={<CheckIcon />}>Complete</Button> : null} */}

                                    {selectedRows.length > 0 && (s_user_rights.isAdmin || s_user_rights.isTransportAdmin) ? <Button variant='contained' color='error' onClick={handleClickOpen} startIcon={<DeleteIcon />}>Delete</Button> : null}
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Default mode &nbsp;&nbsp;    <FormControlLabel checked={expandedTable} control={<Switch onChange={() => setExpandedTable(!expandedTable)} />} label={s_user_rights.columnSelection.length > 0 ? "Custom Mode" : "Expanded Mode"} sx={{ alignItems: "right" }} /> */}
                                </Stack>

                            </Grid>

                        </Grid>}



                    {!isAdding && !isEditing ?
                        isDataLoading ? <LinearProgress /> :
                            <TransportRequisitionsTable users={s_users} requisitions={requisitionList}
                                selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                                setOpenPdfReviewDialog={setOpenPdfReviewDialog} setRequisitionList={setRequisitionList}
                                page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage}
                                selectedColumns={s_user_rights.columnSelection}
                                viewMode={viewMode} autoRefresh={autoRefresh}
                                columns={columns} setColumns={setColumns} allCustomerAddresses={allCustomerAddresses} /> : null}
                    {/* {!isAdding && !isEditing && !action ? <CollectionRequisitionsTable users={s_users} requisitions={s_requisitions} selectedRow={selectedRow} setSelectedRow={setSelectedRow} /> : null} */}
                </Stack>}

            <Box sx={{ pt: 3 }}>
                {isAdding || isEditing ? <TransportRequisitionsForm selectedRequest={s_selectedRequest} handleClose={handleCloseForm} isEditing={isEditing} setIsEditing={setIsEditing} setIsAdding={setIsAdding} setSelectedRows={setSelectedRows} equipmentParts={s_parts} loggedUser={s_user} action={action} userRights={s_user_rights} users={s_users} setOpenSubmittedSnackbar={setOpenSubmittedSnackbar} /> : null}

                {/* {isAdding || isEditing ? 
                <TransportRequisitionsForm 
                collectionID={1} 
                deliveryID={null} 
                portalUserID={{
                    id: 4,
                    user_name: 'Iulia Moraru',
                    user_email: 'iuliamoraru@fitoutuk.com',
                    user_google_uid: 'mw2MfHupRCbWynwHaDDIEllsx952',
                    is_user_active: 1,
                    created_by_id: 1,
                    updated_by_id: 1,
                    created_at: '2023-07-05 09:57:16',
                    updated_at: '2023-07-05 09:57:16'
                }} 
                selectedRequest={{
                    id: "58667",
                    dateNotified: "2023-06-07 10:23:35",
                    userId: "BROlhNqT4TgdDcjUOGcYCicXkM22",
                    action: "Delivery",
                    jobNo: "TEST",
                    customerName: "TEST",
                    addressId: null,
                    customerAddressID: null,
                    propertyNumber: null,
                    address: "TEST",
                    postcode: "S9 1UW",
                    projectName: "TEST",
                    projectNumberCpar: "",
                    salesOrderNumber: "",
                    deliveryContact: "TEST",
                    deliveryContactNumber: "TEST",
                    projectMainContractor: "",
                    projectMainContractorContactName: null,
                    projectMainContractorContactNumber: null,
                    equipmentLocation: "",
                    forkliftAvailable: "0",
                    moffettRequired: "0",
                    moffettDetails: null,
                    dedicatedVehicleRequired: "0",
                    tailLiftVehicleRequired: null,
                    labourOnSite: "0",
                    description: "",
                    specialInstructions: "",
                    transportDepot: "TEST",
                    returnFacility: "",
                    loadingResponsiblePerson: "",
                    driverLoadingTime: null,
                    sdrFormReceived: "0",
                    sdrFormLink: "",
                    earliestDelivery: "1970-01-01 01:00:00",
                    latestDelivery: "1970-01-01 01:00:00",
                    qtyPalletStandard: "1",
                    qtyPalletOversized: "1",
                    totalWeight: "600",
                    palletSpaces: "3",
                    maxVehicleType: "Rigid",
                    status: "Deleted",
                    exportToParagon: "0",
                    picked: null,
                    deleteReason: "NA",
                    projectType: null,
                    projectId: null,
                    lastUpdatedAt: "2023-06-07 10:24:02",
                    lastUpdatedBy: null,
                    lateRequisitionReason: null,
                    portalRequisitionID: "1",
                    portalDeliveryID: null,
                    portalUserID: "2",
                    created_at: "2023-06-07 10:24:02",
                    updated_at: "2023-06-23 08:53:37"
                }} 
                handleClose={handleCloseForm} 
                isEditing={isEditing} 
                setIsEditing={setIsEditing} 
                setIsAdding={setIsAdding} 
                setSelectedRows={setSelectedRows} 
                equipmentParts={s_parts} 
                loggedUser={s_user} 
                action={'SSL ERC Collection (for return and reuse)'} 
                userRights={s_user_rights} 
                users={s_users} 
                setOpenSubmittedSnackbar={setOpenSubmittedSnackbar} /> : null} */}
            </Box>
            <ChangeStatusDialog open={openStatusDialog} setOpen={setOpenStatusDialog} entries={selectedRows} setEntries={setSelectedRows} />

            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openConfirmationSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    {/* {action ? 'Delivery' : 'Collection'} */}
                    Requisition successfully deleted.
                </Alert>
            </Snackbar>
            <Snackbar open={openSubmittedSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    {/* {action ? 'Delivery' : 'Collection'} */}
                    Requisition successfully submitted.
                </Alert>
            </Snackbar>
            <DeleteRequestDialog
                open={openConfirmationDialog} setOpen={setOpenConfirmationDialog}
                selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                deleteReason={deleteReason} setDeleteReason={setDeleteReason}
                canDelete={canDelete}
                handleDeleteTransportRequisition={handleDeleteTransportRequisition}
                handleClose={handleClose} />

            <ParagonErrorDialog
                open={openParagonErrorDialog}
                handleClose={handleClose}
                palletSpacesUnset={palletSpacesUnset} />

            <ChooseJobsParagonDialog
                open={openChooseJobsDialog} setOpen={setOpenChooseJobsDialog} exportParagonFile={exportParagonFile}
                paragonList={paragonList} selectedJobs={selectedJobs} requisitionList={requisitionList}
                setSelectedJobs={setSelectedJobs}
                handleClose={handleClose} />

            {openPdfReviewDialog &&
                <TransportRequisitionReviewDialog pdfRequisition={pdfRequisition} setPdfRequisition={setPdfRequisition} openPdfReviewDialog={openPdfReviewDialog} setOpenPdfReviewDialog={setOpenPdfReviewDialog} handleGeneratePDF={handleDownloadDespatchNote} setSelectedRows={setSelectedRows} user={s_user} />}
        </Box>
    )
}


export default TransportRequisitions