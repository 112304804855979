import PersonIcon from '@mui/icons-material/Person';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiDrawerNav from '../components/nav/MuiDrawerNav';
// import HomePage from '../pages/HomePage';
import { auth } from "../config/fire";
import { useGetUserDoc } from '../config/useFirebase';
import { apiURL, edcUser, getRequest, transportRequestURL } from '../db/apiHandler';
import { useGetAllTransportRequisitionData } from '../db/useGetAllTransportRequisitionData';
import { setTransportRequisitionRights } from '../store/transportRequisitionSlice';
import { setUsers, userDetails } from '../store/userSlice';
import { setAppVersion } from '../store/appSlice';


const LoginHandler = () => {
    const dispatch = useDispatch()

    const s_userDetails = useSelector(userDetails)

    const [loginMode, setLoginMode] = useState("Login")

    const [user, setUser] = useState(null);

    const [canFetchTransportData, setCanFetchTransportData] = useState(false);

    //Get user details if the user has been set
    // useEffect(() => { if (user) { getUsersDetails(user.uid) } }, [user])

    //Firebase Event listner for logging in an out
    onAuthStateChanged(auth, (currentUser) => { setUser(currentUser); });


    //GET USER DOC HOOK
    //Doc holds userdetails and settings
    const userDoc = useGetUserDoc(user, s_userDetails);


    const re = useGetAllTransportRequisitionData(canFetchTransportData);

    useEffect(() => {
        async function fetchData() {
            const response = await getRequest(`${transportRequestURL}/getUserRights/${userDoc.userDoc.uid}`)
                .catch((error) => {
                    console.error('error', error);
                });
            if (response.success) {
                dispatch(setTransportRequisitionRights(response));
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }

            const responseUsers = await getRequest(`${apiURL}/user/getUsersFullDetail`)
                .catch((error) => {
                    console.error('error', error);
                });
            if (responseUsers.success) {
                dispatch(setUsers(responseUsers.response));
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }

            if (response.success && responseUsers.success) {
                if (!canFetchTransportData) {
                    setCanFetchTransportData(true);
                }
            }

        }
        if (userDoc !== null && userDoc.userDoc !== null && userDoc.userDoc.uid) {
            fetchData();
        }
    }, [userDoc]);

    //useSaveToLocalStorage()

    //If there is a user and there is a user doc allow into app
    if (userDoc.success) { return (<MuiDrawerNav></MuiDrawerNav>) }

    return (
        <div >
            {loginMode === "Login" ? <LogInSection setLoginMode={setLoginMode} /> : null}
            {loginMode === "pwReset" ? <ResetPasswordSection setLoginMode={setLoginMode} /> : null}
        </div>
    )
}




const LogInSection = ({ setLoginMode }) => {

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)


    //Log In
    const login = async () => {
        try {
            const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            // console.log(`user`, user)
        } catch {
            setEmailError(true)
            setPasswordError(true)
        }
    };

    //Handle Key Press -> Look for enter key pressed
    const handleKeyDown = (e) => {
        const keyCode = e.keyCode;
        if (keyCode == 13) { login(); }
    }


    return (
        <Box sx={{ height: '100Vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Paper sx={{ p: 3, maxWidth: 300 }}>
                <Stack spacing={1} direction="column">
                    <Typography variant='h5' color='primary'>FIT OUT UK LOGIN</Typography>
                    <TextField fullWidth error={emailError} label="Email" variant="outlined" value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value) }} onKeyDown={handleKeyDown} />
                    <TextField fullWidth error={passwordError} label="Password" type="password" variant="outlined" value={loginPassword} onChange={(e) => { setLoginPassword(e.target.value) }} onKeyDown={handleKeyDown} />
                    <Button fullWidth variant='contained' color='primary' onClick={login}>Log In</Button>
                    {emailError || passwordError ?
                        <>
                            <Typography variant='body2' color='error'>Forgot Password?</Typography>
                            <Button fullWidth variant='contained' color='primary' onClick={() => { setLoginMode("pwReset") }} startIcon={<RotateLeftIcon />}>Reset Password</Button>
                        </>
                        : null
                    }
                </Stack>
            </Paper>
        </Box>
    )
}


const SignUpNewUserSection = ({ setLoginMode }) => {


    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");

    //Regester a new user
    const register = async () => {
        try {
            const user = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
            // console.log(user);
        } catch (error) {
            console.error(error.message);
        }
    };


    return (
        null
    )
}


const ResetPasswordSection = ({ setLoginMode }) => {

    const [loginEmail, setLoginEmail] = useState("");
    const [wasResetError, setWasResetError] = useState(false)
    const [errorEmail, setErrorEmail] = useState("")
    const [resetEmailSent, setResetEmailSent] = useState(false)

    //Reset errors if user trys entering a new email from the one that was an error
    useEffect(() => {
        if (wasResetError) {
            if (loginEmail !== errorEmail) {
                setErrorEmail("")
                setWasResetError(false)
            }
        }
    }, [loginEmail])


    //Handle Reset Password
    const handleResetPassword = async () => {
        await sendPasswordResetEmail(auth, loginEmail)
            .then(response => {
                setResetEmailSent(true)
                setWasResetError(false)
            })
            .catch(err => {
                console.error(`Reset Password Error`, err)
                setResetEmailSent(false)
                setWasResetError(true)
                setErrorEmail(loginEmail)
            })
    }

    //Handle Key Press -> Look for enter key pressed
    const handleKeyDown = (e) => {
        const keyCode = e.keyCode;
        if (keyCode == 13) { handleResetPassword(); }
    }


    //If the email was sent display this message
    if (resetEmailSent) {
        return (
            <Paper >
                <Stack spacing={1} direction="column">
                    <Typography variant='h5' color='primary'>Reset Email Sent</Typography>
                    <Button fullWidth variant='contained' color='primary' onClick={() => { setLoginMode("Login") }}>Back</Button>
                </Stack>
            </Paper>
        )
    }

    //Reset Password Form
    return (
        <Paper >
            <Stack spacing={1} direction="column">
                <Typography variant='h5' color='primary'>Reset Password</Typography>
                <TextField fullWidth error={wasResetError} label="Email" variant="outlined" value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value) }} onKeyDown={handleKeyDown} />
                {wasResetError ? <Typography variant='h6' color='error'>There was an error</Typography> : null}
                {wasResetError ? <Typography variant='subtitle2' color='error'>Please ensure you have entered a valid email address</Typography> : null}
                <Button fullWidth variant='contained' color='primary' onClick={handleResetPassword} endIcon={<SendIcon />}>Send Reset Email</Button>
                <Button fullWidth variant='contained' color='primary' onClick={() => { setLoginMode("Login") }} endIcon={<PersonIcon />}>Back To Login</Button>
            </Stack>
        </Paper>
    )
}



export default LoginHandler
