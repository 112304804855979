import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addresses, customerAddresses } from '../../../store/addressSlice';
import { NoWrapType } from '../../../utils/NoWrapType';
import { columnsList } from '../../settings/Settings';
import { apiURL, getRequest, transportRequestURL } from '../../../db/apiHandler';
import { setAppVersion } from '../../../store/appSlice';


const TransportRequisitionsTable = ({ requisitions, users, setOpenPdfReviewDialog, page, rowsPerPage, setPage, setRowsPerPage, selectedColumns,
    viewMode, autoRefresh, columns, setColumns, selectedRows, setSelectedRows, allCustomerAddresses }) => {

    const dispatch = useDispatch();
    const [columnSelection, setColumnSelection] = useState([]);
    const [requests, setRequests] = useState([]);
    const [isArrowVisible, setIsArrowVisible] = useState(false);
    const [columnName, setColumnName] = useState('');
    const [columnsCopy, setColumnsCopy] = useState(columnsList.map(i => ({ ...i })));


    useEffect(() => {
        let sortingIndex = columns.find(el => el.sortingIndex);
        if (sortingIndex) {
            sortTable(columnsList.find(el => el.columnName === sortingIndex.header).field, sortingIndex.ascSorting);
        }
        else {
            sortTable("latestDelivery", true);
        }
    }, [requisitions])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        if (event.target.value === 'ALL') {
            setRowsPerPage(-1);
        } else {
            setRowsPerPage(parseInt(event.target.value, 10));
        }
        setPage(0);
    };
    useEffect(() => {
        switch (viewMode) {
            case 'default':
                setColumnSelection(['Consignee Company', 'Project Name', 'Action', 'Latest Delivery', 'Postcode', 'Loading Depot', 'Return Facility', 'Store No', 'Store Name',
                    'Special Instructions', 'Pallet Spaces', 'Max Vehicle Type', 'Created By', 'Site Contact Name', 'Site Contact Number']);
                break;
            case 'tableOnly':
                setColumnSelection(['Consignee Company', 'Project Name', 'Action', 'Latest Delivery', 'Postcode', 'Loading Depot', 'Return Facility', 'Store No', 'Store Name',
                    'Special Instructions', 'Pallet Spaces', 'Max Vehicle Type', 'Created By', 'Site Contact Name', 'Site Contact Number']);
                break;
            case 'custom':
                setColumnSelection(selectedColumns.split(","));

                break;
            default:
                setColumnSelection(columnsList.filter(el => el.columnName !== 'Property Number').map(el => el.columnName))
                break;
        }
    }, [selectedColumns, viewMode])

    // SORTING TABLE
    useEffect(() => {
        if (!autoRefresh && columns.length === 0) {
            let a = [];
            columnsList.forEach(el => a.push({
                header: el.columnName,
                ascSorting: false,
                sortingIndex: false,
                width: el.headerMaxWidth
            }))

            setColumns(a);
        }
    }, [columnSelection])

    useEffect(() => {
        if (columnName !== '') {
            const timer = setTimeout(() => {
                setIsArrowVisible(false);
                setColumnName('');
            }, 2000);

            // To clear or cancel a timer, you call the clearTimeout(); method, 
            // passing in the timer object that you created into clearTimeout().

            return () => clearTimeout(timer);
        }
    }, [columnName]);

    const selectRow = (event, row) => {
        if (event.ctrlKey) {
            if (selectedRows.indexOf(row) > -1) {
                setSelectedRows(selectedRows.filter(el => el !== row));
            } else {
                setSelectedRows([...selectedRows, row]);
            }
        } else {
            if (selectedRows.length !== 1) {
                if (selectedRows.indexOf(row) > -1) {
                    setSelectedRows(selectedRows.filter(el => el === row));
                } else {
                    setSelectedRows([row]);
                }
            } else {
                if (selectedRows[0].id === row.id) {

                    setSelectedRows([]);
                } else {
                    setSelectedRows([row]);
                }
            }
        }
        setOpenPdfReviewDialog(false);
    }

    const handleSort = (header) => {
        if (columns.length > 0) {
            setColumnName(header);
            setIsArrowVisible(true);
            let columnsCopy = [...columns];
            columnsCopy.forEach(el => el.sortingIndex = false);
            const foundIndex = columns.findIndex(el => el.header === header);
            columnsCopy[foundIndex].ascSorting = !columns[foundIndex].ascSorting;
            columnsCopy[foundIndex].sortingIndex = true;
            setColumns(columns);
            sortTable(columnsList.find(el => el.columnName === header).field, columnsCopy[foundIndex].ascSorting);
        }
    }

    const sortTable = (header, ascSorting) => {
        let a = [];
        if (requisitions) {
            if (header === 'lastUpdatedAt') {
                requisitions.forEach(el => {
                    let x = JSON.parse(JSON.stringify(el));
                    x.lastUpdatedAt = x.lastUpdatedAt && x.lastUpdatedAt !== '-000001-11-30T00:01:15.000000Z' ? x.lastUpdatedAt : x.dateNotified
                    a.push(x);
                })
            } else {
                a = [...requisitions];
            }
            if (ascSorting) {
                a.sort((a, b) => {
                    let aCopy = { ...a };
                    let bCopy = { ...b };
                    if (a[header] === null || a[header] === undefined) {
                        aCopy[header] = '';
                    }
                    if (b[header] === null || b[header] === undefined) {
                        bCopy[header] = '';
                    }
                    if (typeof aCopy[header] === 'number') {
                        if (aCopy[header] < bCopy[header]) {
                            return -1
                        }
                        if (aCopy[header] > bCopy[header]) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (aCopy[header].toLowerCase() < bCopy[header].toLowerCase()) {
                            return -1
                        }
                        if (aCopy[header].toLowerCase() > bCopy[header].toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                })
            } else {
                a.sort((a, b) => {
                    let aCopy = { ...a };
                    let bCopy = { ...b };
                    if (a[header] === null || a[header] === undefined) {
                        aCopy[header] = '';
                    }
                    if (b[header] === null || b[header] === undefined) {
                        bCopy[header] = '';
                    }
                    if (typeof aCopy[header] === 'number') {
                        if (aCopy[header] < bCopy[header]) {
                            return 1
                        }
                        if (aCopy[header] > bCopy[header]) {
                            return -1;
                        }
                        return 0;
                    } else {
                        if (aCopy[header].toLowerCase() < bCopy[header].toLowerCase()) {
                            return 1
                        }
                        if (aCopy[header].toLowerCase() > bCopy[header].toLowerCase()) {
                            return -1;
                        }
                        return 0;
                    }
                })
            }
            setRequests(a);
        }
    }

    const [selectedColumn, setSelectedColumn] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (event, column) => {
        setAnchorEl(event.currentTarget);
        setSelectedColumn(column);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);
    const id = open ? 'simple-popover' : undefined;


    const [value, setValue] = useState(30);

    const handleChange = (event, newValue) => {
        let a = [...columnsCopy];
        a[a.findIndex(el => el === selectedColumn)].headerMaxWidth = newValue;
        setColumnsCopy(a);
    };

    const GetHeaderColumns = ({ el }) => {

        let column = columnsCopy.find(elem => elem.columnName === el);
        return (
            <TableCell sx={{ color: "inherit", maxWidth: column.rowMaxWidth }} align="left" key={el + ' Header'} >
                <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "left" }}>
                    {columnName === el && isArrowVisible ?
                        <Box>{columns.find(elem => elem.header === el) && columns.find(elem => elem.header === el).ascSorting ?
                            <ArrowDownwardIcon fontSize='small' /> :
                            <ArrowUpwardIcon fontSize='small' />}
                        </Box> : null}
                    <Box onClick={() => handleSort(column.columnName)} >{column.columnName}</Box>
                    {/* <IconButton sx={{marginLeft: 'auto'}} aria-describedby={'id'} variant="contained" onClick={(e) => handleClick(e, column)}>
                        <MoreVertIcon />
                    </IconButton>
                   
                    <Popover
                        id={'id'}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        PaperProps={{
                            style: { width: '18%', overflow: 'inherit' },
                        }}
                    >
                        <div>
                            <Typography sx={{ p: 2 }} variant='subtitle2'>Change '{selectedColumn ? selectedColumn.columnName : ''}' Column Width</Typography>
                            <Slider
                                size="small"
                                aria-label="Small"
                                sx={{ width: '89%', marginLeft: '2vh' }}
                                onChange={handleChange}
                                value={selectedColumn ? columnsCopy.find(el => el.columnName === selectedColumn.columnName).headerMaxWidth : 0}
                                min={0}
                                max={selectedColumn ? columnsList.find(el => el.columnName === selectedColumn.columnName).headerMaxWidth : 0}
                            />
                        </div>
                    </Popover> */}


                </Stack>
            </TableCell>)
    }

    const GetRows = ({ el, row, users }) => {
        let column = columnsList.find(elem => elem.columnName === el);
        if (column.field === 'lastUpdatedAt') {
            return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={moment((row[column.field] && row[column.field] !== '-000001-11-30T00:01:15.000000Z' ? row[column.field] : row.dateNotified)).format('DD-MM-YYYY HH:mm')} /></TableCell>)
        }
        if (column.field === 'latestDelivery' || column.field === 'earliestDelivery' || column.field === 'dateNotified') {
            return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={moment(row[column.field]).format('DD-MM-YYYY HH:mm')} /></TableCell>)
        }
        if ((column.field === 'loadingDepot' && row.action !== 'Delivery') ||
            (column.field === 'returnFacility' && row.action === 'Delivery') ||
            // (column.field === 'driverLoadingTime' && row.action !== 'Delivery') ||
            (column.field === 'projectNumberCpar' && row.action !== 'SSL Collection' && row.action !== 'TSL Collection') ||
            (column.field === 'projectMainContractor' && row.action !== 'SSL Collection' && row.action !== 'TSL Collection') ||
            (column.field === 'equipmentLocation' && row.action === 'Delivery') ||
            (column.field === 'dedicatedVehicleRequired' && row.action === 'Delivery')) {
            return (<TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left" key={row.id}><NoWrapType text={'N/A'} /></TableCell>)
        }
        if (column.field === 'dedicatedVehicleRequired' ||
            column.field === 'forkliftAvailable' ||
            column.field === 'labourOnSite' ||
            column.field === 'sdrFormReceived') {
            if (row[column.field] === null || !row[column.field])
                return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={<CloseIcon />} /></TableCell>)
            else
                return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={<CheckIcon />} /></TableCell>)
        }
        if (column.field === 'totalWeight') {
            return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={row[column.field] / 1000} /></TableCell>)
        }

        if (column.field === 'userId') {
            return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth }} align="left" key={row.id}><NoWrapType text={users.find(el => el.uid === row[column.field]) ? users.find(el => el.uid === row[column.field]).userName : row[column.field] !== null ? row[column.field] : ''} /></TableCell>)
        }
        if (column.field === 'customerAddressID') {
            if (column.columnName === 'Store Name') {
                return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth, p: 1 }} align="left" key={row.id}><NoWrapType text={allCustomerAddresses.find(el => el.id === row[column.field]) ? allCustomerAddresses.find(el => el.id === row[column.field]).customer_address_name : row[column.field] !== null ? 'N/A' : ''} /></TableCell>)
            } else {
                return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth, p: 1 }} align="left" key={row.id}><NoWrapType text={allCustomerAddresses.find(el => el.id === row[column.field]) ? allCustomerAddresses.find(el => el.id === row[column.field]).customers_address_reference : row[column.field] !== null ? 'N/A' : (row.propertyNumber ? row.propertyNumber : '')} /></TableCell>)
            }
        }
        if (column.field === 'lastUpdatedBy') {
            return (<TableCell sx={{ color: 'inherit', maxWidth: column.rowMaxWidth, p: 1 }} align="left" key={row.id}><NoWrapType text={row[column.field] ? users.find(el => el.id === row[column.field]).userName : ''} /></TableCell>)
        }
        return (<TableCell sx={{ color: 'inherit', maxWidth: 110 }} align="left" key={row.id}><NoWrapType text={row[column.field] ? row[column.field] : ''} /></TableCell>)
    }

    if (!allCustomerAddresses) {
        return null;
    }
    return (
        <Box>
            <TableContainer component={Paper} sx={{ maxHeight: viewMode != 'tableOnly' ? '67vh' : window.innerHeight - 170 }}>
                <Table size="small" aria-label="a dense table"  >
                    <TableHead sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        position: 'sticky',
                        top: 0
                    }}>
                        <TableRow>
                            <TableCell sx={{ color: "inherit", maxWidth: 3 }} align="left" key='boxHeader'></TableCell>
                            {columnSelection.map(el => <GetHeaderColumns el={el} />)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && requests && (rowsPerPage > 0
                            ? requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : requests)
                            .map((row, index) => (

                                <Tooltip title={row.action !== 'Delivery' && row.portalDeliveryID ? 'This collection is a backhaul to another delivery.' : ''}>
                                    <TableRow
                                        hover={selectedRows.length > 0 && selectedRows.indexOf(row) > -1 ? false : true}
                                        onClick={(e) => selectRow(e, row)}
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            cursor: selectedRows.length > 0 && selectedRows.indexOf(row) > -1 && 'pointer',
                                            backgroundColor: selectedRows.length > 0 && selectedRows.indexOf(row) > -1 ? 'primary.main' : (row.action !== 'Delivery' && row.portalDeliveryID ? 'aliceblue' : 'inherit'),
                                            color: selectedRows.length > 0 && selectedRows.indexOf(row) > -1 && 'primary.contrastText',
                                        }}
                                    >
                                        <TableCell key='box' sx={{ color: "inherit", maxWidth: 3 }} >
                                            <Box allign="left"
                                                sx={{
                                                    width: 4,
                                                    height: 25,
                                                    backgroundColor: row.action === 'Delivery' ? ((row.projectName && row.projectName.toLowerCase().indexOf('tesco') !== -1) ||
                                                        (row.customerName && row.customerName.toLowerCase().indexOf('tesco') !== -1) ? 'primary.dark' :
                                                        ((row.projectName && (row.projectName.toLowerCase().indexOf('sainsbury') !== -1 ||
                                                            row.projectName.toLowerCase().indexOf('ssl') !== -1)) ||
                                                            (row.customerName && (row.customerName.toLowerCase().indexOf('sainsbury') !== -1 ||
                                                                row.customerName.toLowerCase().indexOf('ssl') !== -1)) ? 'orange' : '#119dda')) : 'green'
                                                }}
                                            />
                                        </TableCell>
                                        {columnSelection.map(el => <GetRows el={el} row={row} users={users} />)}
                                    </TableRow>
                                </Tooltip>
                            ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <TablePagination
                component="div"
                count={requisitions ? requisitions.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100, 'ALL']}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );


}





export default TransportRequisitionsTable;