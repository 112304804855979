

import { Autocomplete, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, TextField, Typography } from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../utils/DateFormat";
import { poscodePattern } from "../../../utils/Postcode";
import { SelectInput } from "../../../utils/SelectInput";
import { customers123 } from "../../settings/CustomersList123";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

const TransportRequisitionReviewDialog = ({ pdfRequisition, openPdfReviewDialog, setOpenPdfReviewDialog, handleGeneratePDF, setSelectedRows, user }) => {
    const [canSave, setCanSave] = useState(false);
    const [requisition, setRequisition] = useState(pdfRequisition);
    const [type, setType] = useState(pdfRequisition ? pdfRequisition.action : '');

    const [latestDelivery, setLatestDelivery] = useState('');

    useEffect(() => {
        if (requisition) {
            if (
                requisition.action === null || requisition.action === '' ||
                requisition.addressFrom === null || requisition.addressFrom === '' ||
                requisition.addressTo === null || requisition.addressTo === '' ||
                requisition.jobNo === null || requisition.jobNo === '' || (requisition.jobNo && requisition.jobNo.replace(/\s/g, '').length === 0) ||
                (requisition.addressFrom && requisition.addressFrom.replace(/\s/g, '').length === 0) ||
                (requisition.addressTo && requisition.addressTo.replace(/\s/g, '').length === 0) ||
                requisition.latestDelivery === null || (requisition.latestDelivery && requisition.latestDelivery.replace(/\s/g, '').length === 0) ||
                requisition.customerName === null || (requisition.customerName && requisition.customerName.replace(/\s/g, '').length === 0)
            )
                setCanSave(false);
            else {
                setCanSave(true);
            }
        } else {
            setRequisition({
                action: '',
                latestDelivery: '',
                qtyPallets: '',
                qtyCages: '',
                description: '',
                specialInstructions: '',
                customerName: '',
                jobNo: '',
                addressFrom: '',
                addressTo: '',
                collectionPostcode: '',
                deliveryPostcode: '',
                collectionContact: '',
                collectionContactNumber: '',
                deliveryContact: '',
                deliveryContactNumber: ''
            })
        }
    }, [requisition])


    useEffect(() => {
        if (pdfRequisition) {
            setLatestDelivery(moment(pdfRequisition.latestDelivery));
            if (pdfRequisition.action === 'Delivery') {
                setRequisition({ ...pdfRequisition, collectionContact: '', collectionContactNumber: '', deliveryPostcode: pdfRequisition.postcode, collectionPostcode: '', qtyCages: '', qtyPallets: '', addressFrom: requisition.transportDepot, addressTo: requisition.address, customerName: (requisition.customerName && requisition.customerName.replace(/\s/g, '').length !== 0 ? requisition.projectName + ' - ' + requisition.customerName : requisition.projectName) });
            } else {
                setRequisition({ ...pdfRequisition, collectionContact: pdfRequisition.deliveryContact, collectionContactNumber: pdfRequisition.deliveryContactNumber, deliveryContact: '', deliveryContactNumber: '', deliveryPostcode: '', collectionPostcode: pdfRequisition.postcode, qtyCages: '', qtyPallets: '', addressFrom: requisition.address, addressTo: requisition.returnFacility, customerName: (requisition.customerName && requisition.customerName.replace(/\s/g, '').length !== 0 ? requisition.projectName + ' - ' + requisition.customerName : requisition.projectName) })
            }
        }
    }, [pdfRequisition])

    const actionType = ['Delivery', 'Collection', 'SSL Collection', 'TSL Collection'];

    const handleClose = () => {
        setOpenPdfReviewDialog(false);
        setSelectedRows([]);
        setRequisition(null);
        setCanSave(false);
    }
    const handleLatestDeliveryDateChanged = (newDate) => {
        // setLatestDelivery(newDate);
        setRequisition({ ...requisition, latestDelivery: formatDate(new Date(newDate)) })
    }

    const handleInputChange = (e) => {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setRequisition({ ...requisition, [name]: value })
    }

    const handleAutocompleteCustomerName = (e, values) => {
        if (values?.label) {
            setRequisition({ ...requisition, customerName: values.label })
        } else {
            setRequisition({ ...requisition, customerName: values })
        }
    }

    const handleSelectActionChanged = (name, value) => {
        setType(value);
        if (value === 'SSL Collection') {
            setRequisition({ ...requisition, action: 'SSL Collection', jobNo: 'ERS24' });
        } else {
            if (value === 'TSL Collection') {
                setRequisition({ ...requisition, action: 'TSL Collection', jobNo: 'ERT24' })
            } else {
                setRequisition({ ...requisition, action: value, jobNo: '' })
            }
        }
    }


    const dispatch = useDispatch();
    const handleOkClose = async () => {
        if (requisition.id) {
            handleGeneratePDF(requisition);
        } else {
            let a = { ...requisition };
            if (a.action === "Delivery") {
                a.address = a.addressTo;
                a.postcode = a.deliveryPostcode;
                a.transportDepot = a.addressFrom + ' ' + a.collectionPostcode;
                a.returnFacility = 'N/A';
                a.projectName = a.customerName;
                a.loadingResponsiblePerson = a.collectionContact;
                a.earliestDelivery = a.latestDelivery;
                a.dateNotified = formatDate(new Date());
                a.qtyPalletStandard = a.qtyPallets === '' ? null : a.qtyPallets;
                a.userId = user.uid;
                a.status = 'NOT PICKED';
            } else {
                a.address = a.addressFrom;
                a.postcode = a.collectionPostcode;
                a.returnFacility = a.addressTo + ' ' + a.deliveryPostcode;
                a.transportDepot = 'N/A';
                a.projectName = a.customerName;
                a.deliveryContact = a.collectionContact;
                a.deliveryContactNumber = a.collectionContactNumber;
                a.earliestDelivery = a.latestDelivery;
                a.dateNotified = formatDate(new Date());
                a.qtyPalletStandard = a.qtyPallets === '' ? null : a.qtyPallets;
                a.userId = user.uid;
                a.status = '';
            }
            // let createError = false;
            // const createResponse = await postRequest(`${transportRequestURL}/createRequest`, a)
            //     .catch(error => {
            //         createError = true;
            //         console.error(error);
            //     });
            // if (createError) {
            // } else {
            //     dispatch(addRequisition(createResponse.response));
            //     a = { ...requisition };
            //     a.id = createResponse.response.transportRequest.id;

            // }
            handleGeneratePDF(a);
        }
        setOpenPdfReviewDialog(false); setRequisition(null); setCanSave(false);
    }

    if (requisition === null) {
        return null;
    }
    return (
        <div>
            <Dialog open={openPdfReviewDialog} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText', fontWeight: 'normal'
                }} variant='contained'>{"Review Requisition info"}</DialogTitle>
                <DialogContent>
                    <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Requisition Details</Divider>
                    <Stack sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "self-end", height: "4vh" }}>
                        {requisition.id ?
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="type"
                                name="type"
                                label="Type"
                                type="text"
                                size="small"
                                variant="outlined"
                                value={requisition.action}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            /> :
                            <SelectInput required name='action' label='Action *' value={type} options={actionType}
                                handleChange={handleSelectActionChanged} />
                        }
                        {requisition.id ?
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="deliveryNoteNo"
                                name="deliveryNoteNo"
                                label="Delivery Note No"
                                type="number"
                                size="small"
                                variant="outlined"
                                value={requisition.id}
                                onChange={handleInputChange}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            /> : null}

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker label='Delivery Date and Time *'
                                renderInput={(props) => <TextField sx={{ marginTop: 1 }}  {...props} variant='standard' InputLabelProps={{ shrink: true }} />}
                                name='latestDelivery'
                                // value={moment(formData.earliestDelivery) || null}
                                value={moment(requisition.latestDelivery) || null}
                                // onChange={handleEarliestDeliveryDateChanged}
                                onChange={handleLatestDeliveryDateChanged}
                                minDateTime={moment(latestDelivery)}
                                required
                                format='DD/MM/YYYY HH:mm'
                                ampm={false}
                                timeSteps={{ minutes: 15 }}
                            />
                        </LocalizationProvider>

                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "self-end", height: "5vh", marginTop: 2, justifyContent: "space-evenly" }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="qtyPallets"
                            name="qtyPallets"
                            label="No. of pallets to deliver"
                            type="number"
                            size="small"
                            variant="outlined"
                            value={requisition.qtyPallets}
                            onChange={handleInputChange}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="qtyCages"
                            name="qtyCages"
                            label="No. of cages to deliver"
                            type="number"
                            size="small"
                            variant="outlined"
                            value={requisition.qtyCages}
                            onChange={handleInputChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Stack>
                    <TextField
                        name='description' label="Description"
                        multiline
                        fullWidth
                        value={requisition.description ? requisition.description : ''}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: 2 }}
                    />
                    <TextField
                        name='specialInstructions' label="Special Instructions"
                        multiline
                        fullWidth
                        value={requisition.specialInstructions ? requisition.specialInstructions : ''}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: 2 }}
                    />

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={4}>
                            <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Customer Details</Divider>
                            {/* <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="customerName"
                                name="customerName"
                                label="Customer Name"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.customerName}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            /> */}
                            <Autocomplete
                                sx={{ zIndex: '1000!important', position: 'static!important', marginTop: 1 }}
                                disablePortal={false}
                                id="customerName"
                                size='small'
                                options={customers123}
                                fullWidth
                                value={requisition.customerName}
                                onChange={handleAutocompleteCustomerName}
                                onInputChange={(e, value) => { setRequisition({ ...requisition, customerName: value }) }}
                                freeSolo
                                renderInput={(params) => <TextField variant='standard' {...params} label="Consignee Company" required
                                    error={requisition.customerName && !customers123.find(el => el.toLowerCase() === requisition.customerName.toLowerCase()) ? true : false}
                                    helperText={requisition.customerName && !customers123.find(el => el.toLowerCase() === requisition.customerName.toLowerCase()) ? 'Not found' : null}
                                    InputLabelProps={{ ...params.InputProps }}
                                />}
                            />
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="jobNo"
                                name="jobNo"
                                label="Job No"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.jobNo}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>From</Divider>
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="addressFrom"
                                name='addressFrom'
                                label="Address"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.addressFrom}
                                onChange={handleInputChange}
                                multiline
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                autoFocus
                                margin="dense"
                                id="collectionPostcode"
                                name="collectionPostcode"
                                label="Postcode"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.collectionPostcode}
                                onChange={handleInputChange}
                                error={requisition.collectionPostcode ? !poscodePattern.test(requisition.collectionPostcode) : false}
                                helperText={requisition.collectionPostcode !== '' && !poscodePattern.test(requisition.collectionPostcode) ? 'Please insert a valid postcode' : ''}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="collectionContact"
                                name="collectionContact"
                                label="Contact Name"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.collectionContact}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="collectionContactNumber"
                                name="collectionContactNumber"
                                label="Contact Number"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.collectionContactNumber}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>To</Divider>
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="addressTo"
                                name='addressTo'
                                label="Address"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.addressTo}
                                onChange={handleInputChange}
                                multiline
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="deliveryPostcode"
                                name="deliveryPostcode"
                                label="Postcode"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.deliveryPostcode || '' || null}
                                onChange={handleInputChange}
                                error={requisition.deliveryPostcode ? !poscodePattern.test(requisition.deliveryPostcode) : false}
                                helperText={requisition.deliveryPostcode !== '' && !poscodePattern.test(requisition.deliveryPostcode) ? 'Please insert a valid postcode' : ''}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="toContact"
                                name="deliveryContact"
                                label="Contact Name"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.deliveryContact}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="toContactNumber"
                                name="deliveryContactNumber"
                                label="Contact Number"
                                type="text"
                                fullWidth
                                size="small"
                                variant="standard"
                                value={requisition.deliveryContactNumber}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    {requisition.id ?
                        <Card sx={{ backgroundColor: "aliceblue" }}>
                            <CardContent>
                                {/* {requisition.id ? */}
                                <Typography sx={{ whiteSpace: 'pre-line', marginTop: '6vh', fontWeight: 'bold', my: 2 }}>
                                    Please note that editing this form will not update the traker data.
                                    Only the PDF file will include the modified entry.
                                </Typography>
                                {/* :
                                
                                <Typography sx={{ whiteSpace: 'pre-line', marginTop: '6vh', fontWeight: 'bold', my: 2 }}>
                                    Please note that this form will also create a new Transport Request in the tracker.
                                </Typography>
                                } */}
                            </CardContent>
                        </Card> : null}
                </DialogContent>
                <DialogActions sx={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOkClose} disabled={!canSave}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TransportRequisitionReviewDialog;