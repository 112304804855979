
export const poscodePattern = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;



export const addAddressComma = (str) => {
    if (str) {
        if (str === '' || (str.length > 0 && str.replace(/\s/g, '').length === 0)) {
            return '';
        }
        return ', ';
    } else {
        return '';
    }
}

export const addAddressNewLine = (str) => {
    if (str) {
        if (str === '' || (str.length > 0 && str.replace(/\s/g, '').length === 0)) {
            return '';
        }
        return '\n ';
    } else {
        return '';
    }
}

