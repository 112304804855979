import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Alert, Autocomplete, Box, Button, Card, CardContent, Divider, FormControlLabel, FormGroup, Grid, IconButton, Paper, Snackbar, Stack, Switch, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { erpSystemURL, getRequest, postRequest, putRequest, transportRequestURL } from '../../../db/apiHandler';
import { createTransportRequest } from '../../../generatedFiles/TransportRequestExcel';
import { addresses, customerAddresses } from '../../../store/addressSlice';
import { addRequisition, editSelectedRequisition } from '../../../store/transportRequisitionSlice';
import { userDetails, users } from '../../../store/userSlice';
import { formatDate } from '../../../utils/DateFormat';
import { poscodePattern } from '../../../utils/Postcode';
import { SelectInput } from '../../../utils/SelectInput';
import { customers123 } from '../../settings/CustomersList123';
import { columnsList } from '../../settings/Settings';
import { devMode } from '../../../db/devMode';
import LateRequisitionReasonDialog from './LateRequisitionReasonDialog';
import { setAppVersion } from '../../../store/appSlice';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { phoneNumberPattern } from '../../importTracker/ImportExportExcel';


export const moffettDetailsOptions = ['Moffett cannot be used at this site', 'Moffett is required to unload the goods', 'Moffett can be used but is not required'];


const TransportRequisitionsForm = ({ selectedRequest, isEditing, equipmentParts, handleClose, loggedUser, action, setIsEditing, setIsAdding, userRights, setSelectedRows, setOpenSubmittedSnackbar }) => {

    const [formData, setFormData] = useState(null);
    const [equipmentToDeliver, setEquipmentToDeliver] = useState(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [formAction, setFormAction] = useState(null);
    const [palletSpaces, setPalletSpaces] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [creator, setCreator] = useState('');

    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isAddressListed, setIsAddressListed] = useState(true);
    const [addressesList, setAddressesList] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState(null);

    const [isPortalEntry, setIsPortalEntry] = useState(false);



    const [lateRequisitionReason, setLateRequisitionReason] = useState(null);
    const [daysTillLatestDeliveryDate, setDaysTillLatestDeliveryDate] = useState(null);
    const [openLateRequisitionDialog, setOpenLateRequitistionDialog] = useState(false);

    const earliestDeliverDate = moment();
    // earliestDeliverDate.setDate(earliestDeliverDate.getDate() + 1);

    const s_user = useSelector(userDetails);
    const s_users = useSelector(users);
    const s_customerAddresses = useSelector(customerAddresses);

    const vehicleType = ['Artic', 'Rigid', 'Van'];
    const actionType = ['Delivery', 'Collection', 'SSL ERC Collection (for return and reuse)', 'TSL ERC Collection (for return and reuse)'];
    const projectNameOptions = ['ABBA', 'Desk', 'Express', 'FELF', 'FESF', 'Get Go', 'GMRS', 'HFSS', 'Large Format', 'PFS', 'River', 'SCO', 'Shelving', 'Travel Money'];
    const dispatch = useDispatch()

    const parts = equipmentParts?.map(el => {

        return {
            label: el.description + ' (' + el.partNumber + ')'
        }
    })

    useEffect(() => {
        if (!isEditing) {
            if (selectedCustomer === 'Tesco') {
                setIsAddressListed(true);
                setSelectedAddress('');
                setFormData({ ...formData, customerName: 'Tesco', address: '', postcode: '', propertyNumber: '' });
                // setAddressList(s_addresses.filter(el => el.clientId === 'Tesco').map(el => el.addressLine1 + addAddressComma(el.addressLine2) + el.addressLine2 + addAddressComma(el.addressLine3) + el.addressLine3 + addAddressComma(el.addressLine4) + el.addressLine4 + addAddressComma(el.postcode) + el.postcode + ' (' + el.id + ')'));
            }
            if (selectedCustomer === "Sainsbury's") {
                setIsAddressListed(true);
                setSelectedAddress('');
                setFormData({ ...formData, customerName: "Sainsbury's", address: '', postcode: '', propertyNumber: '' });
                // setAddressList(s_addresses.filter(el => el.clientId === "Sainsbury's").map(el => el.addressLine1 + addAddressComma(el.addressLine2) + el.addressLine2 + addAddressComma(el.addressLine3) + el.addressLine3 + addAddressComma(el.addressLine4) + el.addressLine4 + addAddressComma(el.postcode) + el.postcode + ' (' + el.id + ')'));
            }
            if (selectedCustomer === 'Other') {
                setSelectedAddress('');
                setFormData({ ...formData, customerName: '', address: '', postcode: '', propertyNumber: '' });
                // setAddressList([]);
                setIsAddressListed(false);
            }
        }
    }, [selectedCustomer])

    useEffect(() => {
        if (isEditing) {
            if (selectedRequest) {
                setEquipmentToDeliver(selectedRequest.equipmentDetails);
                let copyRequisition = { ...selectedRequest };
                copyRequisition.dedicatedVehicleRequired = copyRequisition.dedicatedVehicleRequired === 1 ? true : (copyRequisition.dedicatedVehicleRequired ? true : false);
                copyRequisition.forkliftAvailable = copyRequisition.forkliftAvailable === 1 ? true : (copyRequisition.forkliftAvailable ? true : false);
                copyRequisition.labourOnSite = copyRequisition.labourOnSite === 1 ? true : (copyRequisition.labourOnSite ? true : false);
                copyRequisition.sdrFormReceived = copyRequisition.sdrFormReceived === 1 ? true : (copyRequisition.sdrFormReceived ? true : false);
                setFormData(copyRequisition);
                setTotalWeight(copyRequisition.totalWeight);
                setPalletSpaces(copyRequisition.palletSpaces);
                setFormAction(selectedRequest.action);
                setStartDate(moment(selectedRequest.earliestDelivery));
                setEndDate(moment(selectedRequest.latestDelivery));
                if (selectedRequest.driverLoadingTime) {
                    setDeliveryTime(moment(selectedRequest.driverLoadingTime));
                }
                setSelectedCustomer(copyRequisition.customerName?.toLowerCase().indexOf('tesco') > -1 ? 'Tesco' : copyRequisition.customerName?.toLowerCase().indexOf('sainsbury') > -1 ? "Sainsbury's" : 'Other');
                setSelectedAddress(copyRequisition.customerAddressID ? s_customerAddresses.find(address => address.id === copyRequisition.customerAddressID) : null);
                setAddressesList(copyRequisition.customerName?.toLowerCase().indexOf('tesco') > -1 ? s_customerAddresses.filter(address => address.customer_id === 1) : copyRequisition.customerName?.toLowerCase().indexOf('sainsbury') > -1 ? s_customerAddresses.filter(address => address.customer_id === 2) : [])
                setCreator(s_users.find(el => el.uid === selectedRequest.userId) ? s_users.find(el => el.uid === selectedRequest.userId).userName +
                    ' (' + s_users.find(el => el.uid === selectedRequest.userId).id + ')' : selectedRequest.userId);
                if (selectedRequest.portalUserID) {
                    setIsPortalEntry(true);
                }
            }
        } else {
            setFormData({
                id: null,
                jobNo: '',
                projectName: null,
                address: '',
                customerAddressID: null,
                postcode: '',
                earliestDelivery: '',
                latestDelivery: '',
                deliveryContact: '',
                deliveryContactNumber: '',
                forkliftAvailable: false,
                description: '',
                specialInstructions: '',
                palletSpaces: '',
                qtyPalletStandard: '',
                qtyPalletOversized: '',
                totalWeight: '',
                maxVehicleType: '',
                dateNotified: formatDate(new Date()),
                action: '',
                userId: loggedUser.uid,
                dedicatedVehicleRequired: false,
                labourOnSite: false,
                equipmentLocation: '',
                projectNumberCpar: '',
                projectMainContractor: '',
                transportDepot: '',
                sdrFormReceived: false,
                sdrFormLink: '',
                exportToParagon: '0',
                customerName: '',
                loadingResponsiblePerson: '',
                salesOrderNumber: '',
                driverLoadingTime: '',
                returnFacility: '',
                status: 'NOT PICKED',
                lastUpdatedAt: formatDate(new Date()),
                equipmentDetails: [],
                propertyNumber: null,
                moffettDetails: null,
                cmID: null,
                // isApproved: true,
                // approvedBy: ''
            });

            setTotalWeight(0);
            setPalletSpaces(0);
            setCreator('');
        }
    }, [])


    const handleInputChange = (e) => {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChanged = (name, value) => {
        if (name.target.name === 'isAddressListed') {
            setIsAddressListed(value);
        } else {
            setFormData({ ...formData, [name.target.getAttribute("name")]: value });
        }
    }

    const handleSelectChanged = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectActionChanged = (name, value) => {
        setFormAction(value);
        setEquipmentToDeliver([]);

        if (value === 'SSL ERC Collection (for return and reuse)') {
            setSelectedCustomer("Sainsbury's");
            setFormData({ ...formData, action: 'SSL Collection', jobNo: 'ERS24' })
        } else {
            if (value === 'TSL ERC Collection (for return and reuse)') {
                setSelectedCustomer("Tesco");
                setFormData({ ...formData, action: 'TSL Collection', jobNo: 'ERT24' })
            } else {
                setSelectedCustomer("Other");
                setFormData({ ...formData, action: value, jobNo: '' })
            }
        }
    }


    const handleSetFormData = (e) => {
        const name = e.target.getAttribute('name');
        let value = e.target.value;

        const valueInt = parseInt(value)
        if (valueInt < 0) {
            value = 0
        }
        setFormData({ ...formData, [name]: value })
    }

    const checkCanSave = () => {
        if (formData.jobNo === null || formData.jobNo === '' || (formData.jobNo && formData.jobNo.length > 0 && formData.jobNo.replace(/\s/g, '').length === 0))
            return "Please complete the Job Number field.";
        if (formData.customerName === null || formData.customerName === '' || (formData.customerName && formData.customerName.length > 0 && formData.customerName.replace(/\s/g, '').length === 0))
            return "Please complete the Customer Name field.";
        if (formData.address === null || formData.address === '' || (formData.address && formData.address.length > 0 && formData.address.replace(/\s/g, '').length === 0))
            return "Please complete the Address field.";
        if (formData.postcode === null || formData.postcode === '' || (formData.postcode && formData.postcode.length > 0 && formData.postcode.replace(/\s/g, '').length === 0))
            return "Please complete the Postcode field.";
        if (!poscodePattern.test(formData.postcode))
            return "Please enter a valid Postcode.";
        if (formData.projectName === null || formData.projectName === '' || (formData.projectName && formData.projectName.length > 0 && formData.projectName.replace(/\s/g, '').length === 0))
            return "Please complete the Project Name field.";
        if (formData.deliveryContact === null || formData.deliveryContact === '' || (formData.deliveryContact && formData.deliveryContact.length > 0 && formData.deliveryContact.replace(/\s/g, '').length === 0))
            return "Please complete the Site Contact Name field.";
        if (formData.deliveryContactNumber === null || formData.deliveryContactNumber === '' || (formData.deliveryContactNumber && formData.deliveryContactNumber.length > 0 && formData.deliveryContactNumber.replace(/\s/g, '').length === 0))
            return "Please complete the Site Contact Number field.";
        if (userRights.isContractManager && (formData.description === null || formData.description === '' || (formData.description && formData.description.length > 0 && formData.description.replace(/\s/g, '').length === 0)))
            return "Please complete the Description field.";
        if (formData.qtyPalletStandard === null || formData.qtyPalletStandard === '' || (formData.qtyPalletStandard && formData.qtyPalletStandard.length > 0 && formData.qtyPalletStandard.replace(/\s/g, '').length === 0))
            return "Please complete the Quantity of Standard Pallets field.";
        if (formData.qtyPalletOversized === null || formData.qtyPalletOversized === '' || (formData.qtyPalletOversized && formData.qtyPalletOversized.length > 0 && formData.qtyPalletOversized.replace(/\s/g, '').length === 0))
            return "Please complete the Quantity of Oversized Pallets field.";
        if (totalWeight === null || totalWeight === '' || (totalWeight && totalWeight.length > 0 && totalWeight.replace(/\s/g, '').length === 0))
            return "Please complete the Total Weight field.";
        if (formData.maxVehicleType === null || formData.maxVehicleType === '')
            return "Please complete the Max Vehicle Type field.";
        if (formAction != null && formAction === 'Delivery' && (formData.transportDepot === null || formData.transportDepot === '' || (formData.transportDepot && formData.transportDepot.length > 0 && formData.transportDepot.replace(/\s/g, '').length === 0)))
            return "Please complete the Depot where goods are located/picked field.";
        if (formAction != null && formAction !== 'Delivery' && (formData.returnFacility === null || formData.returnFacility === '' || (formData.returnFacility && formData.returnFacility.length > 0 && formData.returnFacility.replace(/\s/g, '').length === 0)))
            return "Please complete the Return Facility field.";
        if (formData.earliestDelivery && formData.earliestDelivery.length > 0 && formData.earliestDelivery.replace(/\s/g, '').length === 0 || !startDate)
            return "Please complete the Earliest Delivery Date.";
        if (formData.latestDelivery && formData.latestDelivery.length > 0 && formData.latestDelivery.replace(/\s/g, '').length === 0 || !endDate)
            return "Please complete the Latest Delivery Date.";
        if (formAction != null && formAction !== 'Delivery' && (!formData.equipmentLocation || formData.equipmentLocation === '' || (formData.equipmentLocation.length > 0 && formData.equipmentLocation.replace(/\s/g, '').length === 0)))
            return "Please complete the Equipment Location field.";
        if (formAction != null && formAction === 'TSL ERC Collection (for return and reuse)' && formAction === 'SSL ERC Collection (for return and reuse)' && (formData.projectNumberCpar === '' || (formData.projectNumberCpar.length > 0 && formData.projectNumberCpar.replace(/\s/g, '').length === 0)))
            return "Please complete the Project Number or CPAR field.";
        if (formAction != null && formAction === 'TSL ERC Collection (for return and reuse)' && formAction === 'SSL ERC Collection (for return and reuse)' && (formData.projectMainContractor === '' || (formData.projectMainContractor.length > 0 && formData.projectMainContractor.replace(/\s/g, '').length === 0)))
            return "Please complete the Project Main Contractor field.";
        if (equipmentToDeliver === null || equipmentToDeliver?.length === 0)
            return action === 'Delivery' ? "Please add the equipment parts you want to be delivered." : "Please add the equipment parts you want to be collected.";
        if (formData.moffettDetails === null || formData.moffettDetails === '' || (formData.moffettDetails && formData.moffettDetails.length > 0 && formData.moffettDetails.replace(/\s/g, '').length === 0))
            return "Please select an option to complete the `Is Moffett required?` field.";
        return null;
    }

    const formatData = () => {
        let formDataMod = { ...formData };
        formDataMod.earliestDelivery = formatDate(new Date(startDate));
        formDataMod.latestDelivery = formatDate(new Date(endDate));
        if (selectedCustomer !== 'Other') {
            formDataMod.propertyNumber = null;
        } else {
            formDataMod.customerAddressID = null;
        }

        if (deliveryTime) {
            formDataMod.driverLoadingTime = formatDate(new Date(deliveryTime));
        }
        formDataMod.palletSpaces = palletSpaces;
        formDataMod.totalWeight = totalWeight;
        if (formDataMod.driverLoadingTime === '') {
            formDataMod.driverLoadingTime = null;
        }
        formDataMod.equipmentDetails = equipmentToDeliver;
        formDataMod.lateRequisitionReason = lateRequisitionReason;
        switch (formAction) {
            case 'Delivery':
                formDataMod.action = 'Delivery';
                formDataMod.equipmentLocation = '';
                formDataMod.dedicatedVehicleRequired = false;
                formDataMod.labourOnSite = false;
                break;
            case 'Collection':
                formDataMod.action = 'Collection';
                formDataMod.projectNumberCpar = '';
                formDataMod.projectMainContractor = '';
                break;
            default:
                if (formAction === 'SSL ERC Collection (for return and reuse)') {
                    formDataMod.action = 'SSL Collection'
                } else {
                    if (formAction === 'TSL ERC Collection (for return and reuse)') {
                        formDataMod.action = 'TSL Collection'
                    }
                }
                break;
        }

        formDataMod.lastUpdatedAt = formatDate(new Date());
        if (formDataMod.id) {
            formDataMod.lastUpdatedBy = s_user.id;
        }
        return formDataMod;
    }

    function arraysEqual(a, b) {
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        a = a.sort((x, y) => x.equipment - y.equipment);
        b = b.sort((x, y) => x.equipment - y.equipment);

        for (var i = 0; i < a.length; ++i) {
            for (const key in a[i]) {
                if (a[i][key] !== b[i][key] && key !== 'created_at' && key !== 'updated_at' && key !== 'id')
                    return false
            }
        }
        return true;
    }

    const findUpdatedFields = (formDataMod, equipment, oldTransportRequest, oldEquipment) => {
        let updatedFields = [];
        for (const key in formDataMod) {
            if (formDataMod[key] !== oldTransportRequest[key] && key !== 'lastUpdatedAt' && key !== 'equipmentDetails' && key !== 'created_at' && key !== 'updated_at') {
                updatedFields.push({
                    key: key,
                    oldValue: oldTransportRequest[key],
                    columnName: columnsList.find(el => el.field === key)?.columnName
                });
            }
        }

        if (!arraysEqual(equipment, oldEquipment)) {
            return {
                updatedFields: updatedFields,
                oldEquipment: oldEquipment
            }
        }

        return {
            updatedFields: updatedFields
        };

    }

    const sendEmail = async (formDataMod, equipment, oldTransportRequest, oldEquipment) => {
        let updatedFields = {};
        if (oldTransportRequest || oldEquipment) {
            updatedFields = findUpdatedFields(formDataMod, equipment, oldTransportRequest, oldEquipment);
        }

        const excelURL = await createTransportRequest(formDataMod, formDataMod.equipmentDetails, false)
            .catch(err => {
                console.error('excel err', err)
            });
        formDataMod.earliestDelivery = moment(formDataMod.earliestDelivery).format("DD/MM/YYYY HH:mm").toString()
        formDataMod.latestDelivery = moment(formDataMod.latestDelivery).format("DD/MM/YYYY HH:mm").toString()
        let creator = null;
        if (formDataMod && formDataMod.userId) {
            creator = s_users.find(user => user.uid === formDataMod.userId);
        }
        let emailAddresses = devMode ? [
            {
                "email": s_user.userEmail,
                "name": s_user.userName
            }] : [
            {
                "email": "transport@fitoutuk.com",
                "name": "Admin Transport"
            },
            {
                "email": "Transport@prl-logistics.com",
                "name": "Transport"
            },
            {
                "email": "derekmorley@fitoutuk.com",
                "name": "Derek Morley"
            },
            {
                "email": s_user.userEmail,
                "name": s_user.userName
            }]
        if ((formDataMod.action === 'SSL Collection' || formDataMod.action === 'TSL Collection') && !devMode) {
            emailAddresses.push({
                "email": 'erc@fitoutuk.com',
                "name": 'ERC FitOutUK'
            })
        }
        if (formDataMod.action === 'TSL Collection' && !devMode) {
            emailAddresses.push({
                "email": 'admin@edc-equipment.com',
                "name": 'Admin EDC'
            })
        }
        if (creator && !devMode) emailAddresses.push(
            {
                "email": creator.userEmail,
                "name": creator.userName
            })
        if ((formDataMod.jobNo === 'ERL24' || formDataMod.jobNo === '10020D' || formDataMod.jobNo === '10020') && !devMode) {
            emailAddresses.push(
                {
                    "email": 'liamakers@fitoutuk.com',
                    "name": 'Liam Akers'
                });
            emailAddresses.push(
                {
                    "email": 'timbrewster@fitoutuk.com',
                    "name": 'Tim Brewster'
                });
        }
        const data = {
            emailAddresses: emailAddresses,
            subject: formDataMod.id ? "Transport Requisition Update - " + formDataMod.projectName : "New Transport Requisition - " + formDataMod.projectName,
            title: formDataMod.id ? "This transport requisition has been updated." : ("A new transport requisition has been submitted. "),
            subtitle: lateRequisitionReason ? "Reason for late submission of the request: " + lateRequisitionReason : null,
            requisition: formDataMod,
            message: "Test message",
            excelURL: excelURL,
            updatedFields: updatedFields
        }
        const sendEmailResponse = await postRequest(`${transportRequestURL}/email/validation`, data)
            .catch(error => {
                console.error(error);
            });
    }

    const datediff = (first, second) => {
        return Math.floor((Date.parse(second) - Date.parse(first)) / 86400000);
    }


    const checkLateRequisition = () => {
        if (datediff(new Date(), new Date(endDate)) < 3) {
            setDaysTillLatestDeliveryDate(datediff(new Date(), new Date(endDate)) + 1);
            setOpenLateRequitistionDialog(true);
        } else {
            createTransportRequestDB();
        }
    }


    const createTransportRequestDB = async () => {
        if (formData.id !== null) {
            updateTransportRequestDB();
        } else {
            let formDataMod = formatData();
            if (checkCanSave() !== null) {
                setErrorMessage(checkCanSave());
                setOpenErrorSnackbar(true);
            } else {
                const createResponse = await postRequest(`${transportRequestURL}/createRequest`, formDataMod)
                    .catch(error => {
                        console.error(error);
                    });
                if (createResponse.success) {
                    sendEmail(formDataMod);
                    setFormData(createResponse.response.transportRequest);
                    dispatch(addRequisition(createResponse.response));
                    setFormAction(createResponse.response.transportRequest.action);
                    setIsEditing(false);
                    setIsAdding(false);
                    setSelectedRows([]);
                    setOpenSubmittedSnackbar(true);
                } else {
                    if (createResponse.status === 505) {
                        dispatch(setAppVersion('CONFLICT'));
                    }
                }
            }
        }
    }

    const updateTransportRequestDB = async () => {
        let formDataMod = formatData();
        if (checkCanSave() !== null) {
            setErrorMessage(checkCanSave());
            setOpenErrorSnackbar(true);
        } else {
            const response = await putRequest(`${transportRequestURL}/editRequest/${formDataMod.id}`, formDataMod)
                .catch(error => {
                    console.error(error);
                });

            if (response.success) {
                sendEmail(formDataMod, response.response.transportRequestEquipmentDetails, response.response.oldTransportRequest, response.response.oldTransportRequestEquimentDetails);
                dispatch(editSelectedRequisition(response.response));
                setIsAdding(false);
                setIsEditing(false);
                setSelectedRows([]);
                setOpenSubmittedSnackbar(true);
            } else {
                if (response.status === 505) {
                    dispatch(setAppVersion('CONFLICT'));
                }
            }
        }
    }


    const handleCloseSnackbar = () => {
        setOpenErrorSnackbar(false);
        setOpenConfirmationSnackbar(false);
    };

    const handleSetFormDataPalletSpacesTotalWeight = (e) => {

        const name = e.target.getAttribute('name');
        let value = e.target.value;

        const valueInt = parseInt(value)
        if (valueInt < 0) {
            value = 0
        }
        let qtyPalletStandard = formData.qtyPalletStandard ? formData.qtyPalletStandard : 0;
        let qtyPalletOversized = formData.qtyPalletOversized ? formData.qtyPalletOversized : 0;
        if (value) {
            if (name === 'qtyPalletStandard') {
                qtyPalletStandard = value;
            } else {
                qtyPalletOversized = value;
            }
        } else {
            if (name === 'qtyPalletStandard') {
                qtyPalletStandard = 0;
            } else {
                qtyPalletOversized = 0;
            }
        }
        setPalletSpaces(
            parseInt(qtyPalletStandard) +
            parseInt(qtyPalletOversized) * 2);
        setTotalWeight(parseInt(qtyPalletStandard) * 200 +
            parseInt(qtyPalletOversized) * 2 * 200)

        setFormData({ ...formData, [name]: value })
    }

    const handleSetFormDataPalletSpaces = (e) => {
        let value = e.target.value;

        const valueInt = parseInt(value)
        if (valueInt < 0) {
            value = 0
        }
        setPalletSpaces(value);
    }
    const handleSetFormDataTotalWeight = (e) => {
        let value = e.target.value;

        const valueInt = parseInt(value)
        if (valueInt < 0) {
            value = 0
        }
        setTotalWeight(value);
    }

    const handleAutocompleteChanged = (e, values) => {
        if (values) {
            let index = values.lastIndexOf('(');
            if (index > -1) {
                let idString = values.slice(index + 1);
                idString = idString.slice(0, -1);
                setFormData({ ...formData, userId: s_users.find(el => el.id === Number(idString)).uid });
            }
        }
        setCreator(values);

    }

    const handleSelectStore = async (e, values) => {
        if (values) {
            let splittedArray = values.split(" - ");
            let storeNumber = splittedArray[0];
            const valuesInBrackets = values.match(/\((.*?)\)/g);
            // let id = Number(values.split('(')[1].split(')')[0]);
            let id = Number(valuesInBrackets[valuesInBrackets.length - 1].slice(1, -1));
            let clientID = null;
            if (selectedCustomer === 'Tesco') {
                clientID = 2;
            } else {
                clientID = 1;
            }
            const foundCustomerAddress = s_customerAddresses.find(el => el.id === id);
            const foundAddressResponse = await getRequest(`${erpSystemURL}/addresses/Search?where=id=${foundCustomerAddress.customer_address_id}`, true);
            let foundAddress = foundAddressResponse.response[0];
            let concatAddress = '';
            setSelectedAddress(foundCustomerAddress)
            if (foundAddress) {
                concatAddress = addAddressCommaToString(foundAddress.address1) + addAddressCommaToString(foundAddress.address2) + addAddressCommaToString(foundAddress.address3) + addAddressCommaToString(foundAddress.address4, true);
                if (concatAddress[concatAddress.length - 2] === ',' && concatAddress[concatAddress.length - 1] === ' ') {
                    concatAddress = concatAddress.slice(0, -2);
                }
            }
            setFormData({
                ...formData,
                address: concatAddress,
                postcode: foundAddress ? foundAddress.post_code : '',
                customerAddressID: foundCustomerAddress.id
            });
        }
    }

    const handleAutocompleteProjectName = (e, values) => {
        if (values?.label) {
            setFormData({ ...formData, projectName: values.label })
        } else {
            setFormData({ ...formData, projectName: values })
        }
    }
    const handleAutocompleteCustomerName = (e, values) => {
        if (values?.label) {
            setFormData({ ...formData, customerName: values.label })
        } else {
            setFormData({ ...formData, customerName: values })
        }
    }


    if (!formData) { return null }
    return (
        <Box>{isPortalEntry ?
            <Stack sx={{ p: 1 }}>
                <Card sx={{ backgroundColor: "aliceblue", height: '3vw' }}>
                    <CardContent sx={{}}>
                        <Typography sx={{ whiteSpace: 'pre-line', fontWeight: 'bold' }}>
                            Please note this transport request has been created on the ERP Portal, therefore some fields will be unavailable to ammend.
                        </Typography>
                    </CardContent>
                </Card>
            </Stack> : null}
            <Paper>
                <Stack spacing={1} direction='row' sx={{ mb: 2 }}>
                    <Button variant='contained' color='error' onClick={handleClose}>CLOSE</Button>
                    {(!isEditing || (isEditing && !formData.id)) && <Button variant='contained' color='primary' onClick={checkLateRequisition}>SAVE</Button>}
                    {isEditing && formData.id && ((userRights.isContractManager && formData.userId === loggedUser.uid) || userRights.isTransportAdmin || userRights.isAdmin) && <Button variant='contained' color='success' onClick={updateTransportRequestDB}>SAVE</Button>}
                </Stack>
                <Typography color='primary' variant='h4'>TRANSPORT {formAction == null ? '' : formAction.toUpperCase()} REQUISITION</Typography>
            </Paper>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={4}>
                    <Paper sx={{ p: 2 }}>
                        <Stack spacing={1}>
                            {!isEditing || formAction == null ? <SelectInput required name='action' label='Action *' value={formAction ? formAction : ''} options={actionType}
                                handleChange={handleSelectActionChanged} /> : null}
                            {formAction != null ? <Box>
                                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>PROJECT INFO</Divider>
                                <Stack>
                                    {(formAction === 'Delivery' || formAction === 'Collection') && !isEditing &&
                                        <Stack spacing={3} direction='row' sx={{ alignSelf: 'center' }}>
                                            <Button variant={selectedCustomer === "Tesco" ? "contained" : "outlined"} onClick={() => setSelectedCustomer('Tesco')}>Tesco</Button>
                                            <Button variant={selectedCustomer === "Sainsbury's" ? "contained" : "outlined"} onClick={() => setSelectedCustomer("Sainsbury's")}>Sainsbury's</Button>
                                            <Button variant={selectedCustomer === 'Other' ? "contained" : "outlined"} onClick={() => setSelectedCustomer('Other')}>Other</Button>
                                        </Stack>}
                                    <TextField required autoComplete='off' variant='standard' size='small' name='jobNo' label='Job No.' inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        value={formData && formData.jobNo ? formData.jobNo : ''} onChange={(e) => {
                                            const name = e.target.getAttribute("name");
                                            const value = e.target.value;
                                            setFormData({ ...formData, [name]: value.toUpperCase() })
                                        }} />

                                    <Autocomplete
                                        sx={{ zIndex: '1000!important', position: 'static!important', marginTop: 1 }}
                                        disablePortal
                                        id="customerName"
                                        size='small'
                                        options={customers123}
                                        fullWidth
                                        value={formData.customerName}
                                        onChange={handleAutocompleteCustomerName}
                                        onInputChange={(e, value) => { setFormData({ ...formData, customerName: value }) }}
                                        readOnly={isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                        freeSolo
                                        renderInput={(params) => <TextField variant='standard' {...params} label="Consignee Company" required
                                            // error={formData.customerName && !customers123.find(el => el.toLowerCase() === formData.customerName.toLowerCase()) ? true : false}
                                            // helperText={formData.customerName && !customers123.find(el => el.toLowerCase() === formData.customerName.toLowerCase()) ? 'Not found' : null}
                                            InputLabelProps={{ ...params.InputProps }}
                                        />}
                                    />
                                    {/* <TextField required autoComplete='off' variant='standard' size='small' name='customerName' label='Customer name' inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        value={formData && formData.customerName ? formData.customerName : ''} onChange={handleInputChange} /> */}
                                    {formAction === 'SSL ERC Collection (for return and reuse)' || formAction === 'TSL ERC Collection (for return and reuse)' ?
                                        <TextField required autoComplete='off' variant='standard' size='small' name='projectNumberCpar'
                                            label={formAction === 'SSL ERC Collection (for return and reuse)' ? 'CPAR' : 'Project Number'} inputProps={{ readOnly: isPortalEntry || (isEditing && (selectedRequest.projectNumberCpar || userRights.isContractManager)) || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                            value={formData && formData.projectNumberCpar ? formData.projectNumberCpar : ''} onChange={handleInputChange}
                                            error={formData && formData.projectNumberCpar ? isNaN(formData.projectNumberCpar) : false}
                                            helperText={isNaN(formData.projectNumberCpar) ? ((formAction === 'SSL ERC Collection (for return and reuse)' ? 'CPAR' : 'Project Number') + ' should not contain letters.') : ''} /> : null}
                                    {selectedCustomer && (selectedCustomer === 'Tesco' || selectedCustomer === "Sainsbury's") &&
                                        <Autocomplete
                                            sx={{ zIndex: '1000!important', position: 'static!important', marginTop: 1 }}
                                            // disablePortal
                                            id="address"
                                            size='small'
                                            options={
                                                selectedCustomer === 'Tesco' ? s_customerAddresses.filter(address => address.customer_id === 2).map(store => store.customers_address_reference + ' - ' + store.customer_address_name + ' (' + store.id + ')') :
                                                    selectedCustomer === "Sainsbury's" ? s_customerAddresses.filter(address => address.customer_id === 1).map(store => store.customers_address_reference + ' - ' + store.customer_address_name + ' (' + store.id + ')') :
                                                        s_customerAddresses.map(store => store.customers_address_reference + ' - ' + store.customer_address_name + ' (' + store.id + ')')}
                                            fullWidth
                                            value={selectedAddress ? selectedAddress.customers_address_reference.toString() + ' - ' + selectedAddress.customer_address_name + ' (' + selectedAddress.id + ')' : ''}
                                            onChange={(e, value) => handleSelectStore(e, value)}
                                            readOnly={isPortalEntry || (isEditing && selectedAddress && userRights.isContractManager) || (userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                            renderInput={(params) => <TextField variant='standard' {...params} label="Store number *" />}
                                        />}
                                    {selectedCustomer === 'Other' &&
                                        <TextField autoComplete='off' variant='standard' size='small' name='propertyNumber' label='Propery/Store Number'
                                            type='number'
                                            inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                            sx={{ marginTop: 1 }} value={formData && formData.propertyNumber ? formData.propertyNumber : ''} onChange={handleInputChange} />}

                                    {/* {(!isAddressListed || isEditing) && */}
                                    <TextField required autoComplete='off' variant='standard' size='small' name='address' label='Address'
                                        inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        sx={{ marginTop: 1 }} value={formData && formData.address ? formData.address : ''} onChange={handleInputChange} />
                                    {/* } */}
                                    {/* {(!isAddressListed || isEditing) && */}
                                    <TextField required autoComplete='off' variant='standard' size='small' name='postcode' label='Postcode'
                                        inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        sx={{ marginTop: 1 }} value={formData.postcode ? formData.postcode : ''} onChange={handleInputChange}
                                        error={formData.postcode ? !poscodePattern.test(formData.postcode) : false}
                                        helperText={formData.postcode !== '' && !poscodePattern.test(formData.postcode) ? 'Please insert a valid postcode' : ''} />
                                    {/* } */}
                                    {/* {(selectedCustomer !== 'Other' && !isEditing) &&
                                        <FormControlLabel control={<Switch checked={isAddressListed} onChange={handleSwitchChanged} />}
                                            sx={{ marginTop: 1, marginBottom: 2 }} name='isAddressListed' label={`Address option listed`} />} */}
                                    {/* <TextField required autoComplete='off' variant='standard' size='small' name='projectName' label='Project Name' inputProps={{ readOnly: isEditing && (selectedRequest.projectName || userRights.isContractManager) }}
                                        sx={{ marginTop: 1 }} value={formData && formData.projectName ? formData.projectName : ''} onChange={handleInputChange} /> */}
                                    <Autocomplete
                                        sx={{ zIndex: '1000!important', position: 'static!important', marginTop: 1 }}
                                        disablePortal
                                        id="projectName"
                                        size='small'
                                        options={projectNameOptions}
                                        fullWidth
                                        freeSolo
                                        value={formData.projectName}
                                        onInputChange={(e, value) => { setFormData({ ...formData, projectName: value }) }}
                                        onChange={handleAutocompleteProjectName}
                                        readOnly={isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                        renderInput={(params) => <TextField variant='standard' {...params} label="Project name" required
                                            InputLabelProps={{ ...params.InputProps }}
                                        />}
                                    />
                                    {formAction === 'Delivery' ?
                                        <TextField autoComplete='off' variant='standard' size='small' name='salesOrderNumber' label='Sales Order Number'
                                            inputProps={{ readOnly: userRights.isContractManager && formData.userId !== loggedUser.uid }}
                                            sx={{ marginTop: 1 }} value={formData && formData.salesOrderNumber ? formData.salesOrderNumber : ''} onChange={handleInputChange} /> : null}



                                    {isEditing && creator && (userRights.isAdmin || userRights.isTransportAdmin) &&
                                        <Autocomplete
                                            disablePortal
                                            id="creator"
                                            options={s_users && s_users.map(el => el.userName + ' (' + el.id + ')')}
                                            fullWidth
                                            size='small'
                                            sx={{ marginTop: 1 }}
                                            value={creator || null}
                                            onChange={(e, value) => handleAutocompleteChanged(e, value)}
                                            renderInput={(params) => <TextField variant='standard' {...params} label="Request created by" />}
                                            readOnly={isPortalEntry}

                                        />
                                    }
                                </Stack>

                                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>CONTACT INFO</Divider>
                                <Stack>
                                    {formAction === 'SSL ERC Collection (for return and reuse)' || formAction === 'TSL ERC Collection (for return and reuse)' ?
                                        <TextField autoComplete='off' variant='standard' size='small' name='projectMainContractor' label='Project Main Contractor' required
                                            sx={{ marginTop: 1 }} value={formData.projectMainContractor ? formData.projectMainContractor : ''} onChange={handleInputChange}
                                            inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} /> : null}
                                    <TextField required autoComplete='off' variant='standard' size='small' name='deliveryContact' label='Site Contact Name'
                                        sx={{ marginTop: 1 }} value={formData.deliveryContact ? formData.deliveryContact : ''} onChange={handleInputChange}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} />
                                    <TextField required autoComplete='off' variant='standard' size='small' name='deliveryContactNumber' label='Site Contact Number'
                                        sx={{ marginTop: 1 }} value={formData.deliveryContactNumber ? formData.deliveryContactNumber : ''} onChange={handleInputChange}
                                        error={formData.deliveryContactNumber ? !phoneNumberPattern.test(formData.deliveryContactNumber) : false}
                                        helperText={formData.deliveryContactNumber !== '' && !phoneNumberPattern.test(formData.deliveryContactNumber) ? 'Please insert a valid phone number. \n Valid Format: 07xxxxxxxxx' : ''}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} />
                                    {formAction !== 'Delivery' ? <TextField required autoComplete='off' variant='standard' size='small' name='equipmentLocation' label='Location of Equipment'
                                        sx={{ marginTop: 1 }} value={formData.equipmentLocation ? formData.equipmentLocation : ''} onChange={handleInputChange}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} /> : null}
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={formData.forkliftAvailable} onChange={handleSwitchChanged} disabled={(userRights.isContractManager && formData.userId !== loggedUser.uid)} />}
                                            sx={{ marginTop: 1 }} name='forkliftAvailable' label={`Is Fork Lift Truck Available On Site?`} />
                                        {/* <FormControlLabel control={<Switch checked={formData.moffettRequired} onChange={handleSwitchChanged} disabled={(userRights.isContractManager && formData.userId !== loggedUser.uid)} />}
                                            sx={{ marginTop: 1 }} name='moffettRequired' label='Is Moffett Required?' /> */}
                                        {/* {formData.moffettRequired ? */}
                                        <SelectInput required name='moffettDetails' label='Is Moffett Required? *' value={formData.moffettDetails ? formData.moffettDetails : ''} options={moffettDetailsOptions}
                                            handleChange={(name, value) => setFormData({ ...formData, moffettDetails: value })} width={'28vw'} />
                                        {/* : null} */}
                                        {formAction !== 'Delivery' ? <FormControlLabel control={<Switch checked={formData.labourOnSite} onChange={handleSwitchChanged} disabled={(userRights.isContractManager && formData.userId !== loggedUser.uid)} />}
                                            sx={{ marginTop: 1 }} name='labourOnSite' label={`Is There Labour On Site To Help Load?`} /> : null}
                                        {formAction !== 'Delivery' ? <FormControlLabel control={<Switch checked={formData.dedicatedVehicleRequired} onChange={handleSwitchChanged} disabled={(userRights.isContractManager && formData.userId !== loggedUser.uid)} />}
                                            sx={{ marginTop: 1 }} name='dedicatedVehicleRequired' label={`Dedicated Vehicle Required?`} /> : null}
                                    </FormGroup>


                                    <TextField
                                        required={userRights.isContractManager}
                                        name='description' label={`Description of goods`}
                                        multiline
                                        value={formData.description ? formData.description : ''}
                                        onChange={handleInputChange}
                                        // InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1 }}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                    />

                                    <TextField
                                        name='specialInstructions' label={formAction === 'Delivery' ? `Special instructions for delivery` : `Special instructions for collection`}
                                        multiline
                                        value={formData.specialInstructions ? formData.specialInstructions : ''}
                                        onChange={handleInputChange}
                                        // InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1 }}
                                        inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                    />
                                </Stack>
                                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>DELIVERY INFO</Divider>
                                <Stack spacing={1}>
                                    {formAction === 'Delivery' ?
                                        <TextField required autoComplete='off' variant='standard' size='small' name='transportDepot' label='Depot where goods are located/picked'
                                            sx={{ marginTop: 1 }} value={formData.transportDepot ? formData.transportDepot : ''} onChange={handleInputChange}
                                            inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        /> : null}
                                    {(userRights.isAdmin || userRights.isTransportAdmin) ?
                                        <TextField autoComplete='off' variant='standard' size='small' name='sdrFormLink' label='SDR Form Link'
                                            sx={{ marginTop: 1 }} value={formData.sdrFormLink ? formData.sdrFormLink : ''} onChange={handleInputChange}
                                            inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} /> : null}
                                    {(userRights.isAdmin || userRights.isTransportAdmin) ?
                                        <FormControlLabel control={<Switch checked={formData.sdrFormReceived} onChange={handleSwitchChanged} />}
                                            sx={{ marginTop: 1 }} name='sdrFormReceived' label={`SDR Form Received`} /> : null}
                                    {formAction !== 'Delivery' ?
                                        <TextField autoComplete='off' variant='standard' size='small' name='returnFacility' label='Return Facility' required
                                            sx={{ marginTop: 1, marginBottom: 2 }} value={formData.returnFacility ? formData.returnFacility : ''} onChange={handleInputChange}
                                            inputProps={{ readOnly: isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid) }} /> : null}
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateTimePicker label={formAction === 'Delivery' ? 'Earliest Delivery *' : 'Earliest Collection *'}
                                            readOnly={isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                            renderInput={(props) => <TextField sx={{ marginTop: 1 }}  {...props} variant='standard' InputLabelProps={{ shrink: true }} />}
                                            name='earliestDelivery'
                                            // value={moment(formData.earliestDelivery) || null}
                                            value={startDate || null}
                                            // onChange={handleEarliestDeliveryDateChanged}
                                            onChange={(newDate) => { setStartDate(newDate); setEndDate(newDate.set({ millisecond: newDate.millisecond() + 1 })); }}
                                            minDateTime={isEditing ? moment(formData.earliestDelivery) < moment() ? moment(formData.earliestDelivery) : moment() : moment()}
                                            required
                                            format='DD/MM/YYYY HH:mm'

                                            ampm={false}
                                            timeSteps={{ minutes: 15 }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateTimePicker label={formAction === 'Delivery' ? 'Latest Delivery *' : 'Latest Collection *'}
                                            readOnly={isPortalEntry || (userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                            renderInput={(props) => <TextField sx={{ marginTop: 2 }}  {...props} variant='standard' InputLabelProps={{ shrink: true }} />}
                                            name='latestDelivery'
                                            // value={formData.latestDelivery || null}
                                            value={endDate || null}
                                            // onChange={handleLatestDeliveryDateChanged}
                                            onChange={(newDate) => setEndDate(newDate)}
                                            minDateTime={isEditing ? moment(startDate) < moment() ? moment() : moment(startDate) : moment()}
                                            disabled={!startDate}
                                            required
                                            format='DD/MM/YYYY HH:mm'

                                            ampm={false}
                                            timeSteps={{ minutes: 15 }}
                                        />
                                    </LocalizationProvider>
                                    {/* {formAction === 'Delivery' && (userRights.isAdmin || userRights.isTransportAdmin) ?
                                        <TextField autoComplete='off' variant='standard' size='small' name='loadingResponsiblePerson' label='Designate Person for Loading'
                                            sx={{ marginTop: 1 }} value={formData.loadingResponsiblePerson ? formData.loadingResponsiblePerson : ''} onChange={handleInputChange} /> : null}
                                    {formAction === 'Delivery' && (userRights.isAdmin || userRights.isTransportAdmin) ?
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker label='Driver Loading Time '
                                                renderInput={(props) => <TextField sx={{ marginTop: 2 }}  {...props} variant='standard' />}
                                                name='driverLoadingTime'
                                                value={deliveryTime || null}
                                                onChange={(newDate) => setDeliveryTime(newDate)}
                                                required
                                                inputFormat='DD/MM/YYYY hh:mm a'
                                            />
                                        </LocalizationProvider> : null} */}
                                </Stack>
                                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>PALLETS INFO</Divider>
                                <Stack>
                                    <TextField required type='number' autoComplete='off' variant='standard' size='small' name='qtyPalletStandard'
                                        sx={{ marginTop: 1 }} label='Quantity of Standard Pallets' value={formData.qtyPalletStandard ? formData.qtyPalletStandard : ''} onChange={handleSetFormDataPalletSpacesTotalWeight}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} />
                                    <TextField required type='number' autoComplete='off' variant='standard' size='small' name='qtyPalletOversized'
                                        sx={{ marginTop: 1 }} label='Quantity of Oversized Pallets' value={formData.qtyPalletOversized ? formData.qtyPalletOversized : ''} onChange={handleSetFormDataPalletSpacesTotalWeight}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} />
                                    <TextField InputLabelProps={{ shrink: true }} required type='number' autoComplete='off' variant='standard' size='small' name='totalWeight' label='Total weight (Tonnes)'
                                        sx={{ marginTop: 1 }} value={totalWeight / 1000} onChange={handleSetFormDataTotalWeight}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }} />
                                    <TextField InputLabelProps={{ shrink: true }} required type='number' autoComplete='off' variant='standard' size='small' name='palletSpaces'
                                        sx={{ marginTop: 1 }} label='Pallet Spaces' onChange={handleSetFormDataPalletSpaces}
                                        inputProps={{ readOnly: (userRights.isContractManager && formData.userId !== loggedUser.uid) }}
                                        value={palletSpaces} />
                                    <SelectInput name='maxVehicleType' label='Max vehicle type permited *' variant='standard'
                                        readOnly={(userRights.isContractManager && formData.userId !== loggedUser.uid)}
                                        value={formData.maxVehicleType ? formData.maxVehicleType : ''} options={vehicleType} handleChange={handleSelectChanged} />
                                </Stack>
                            </Box> : null}
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={{ p: 2 }}>
                        <EquipmentToDeliver equipmentParts={parts} equipmentToDeliver={equipmentToDeliver} setEquipmentToDeliver={setEquipmentToDeliver} formEditing={isEditing} action={formAction} userRights={userRights} />
                    </Paper>
                </Grid>
            </Grid>

            {openLateRequisitionDialog &&
                <LateRequisitionReasonDialog open={openLateRequisitionDialog} setOpen={setOpenLateRequitistionDialog}
                    lateRequisitionReason={lateRequisitionReason} setLateRequisitionReason={setLateRequisitionReason}
                    createTransportRequest={createTransportRequestDB} daysTillLatestDeliveryDate={daysTillLatestDeliveryDate} />}
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openConfirmationSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    {formAction} Requisition successfully submitted.
                </Alert>
            </Snackbar>

        </Box>
    )
}


const EquipmentToDeliver = ({ equipmentParts, equipmentToDeliver, setEquipmentToDeliver, action, userRights, formEditing }) => {
    const [formData, setFormData] = useState({
        equipment: '',
        quantity: '',
        dept: '',
        unit: '',
        action: '',
        palletsOrCages: '',
        newOrUsed: ''
    });


    const deptOptions = ['FOM', 'ERC', 'PURCHASING', 'WAREHOUSE', 'H&S', 'FOUK PLANT', 'IT', 'OTHER'];
    const actionOptions = ['Ringfence', 'Restock', 'Dispose'];
    const palletsOrCagesOptions = ['Pallets', 'Cages'];
    const newOrUsedOptions = ['New', 'Used'];

    const [isEditing, setIsEditing] = useState(false);
    const [id, setId] = useState(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleInputChange = (e) => {
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handleAutocompleteChanged = (e, values) => {
        if (values?.label) {
            setFormData({ ...formData, equipment: values.label })
        } else {
            setFormData({ ...formData, equipment: values })
        }
    }

    const handleSelectChanged = (name, value) => {

        setFormData({ ...formData, [name]: value })
    }

    const handleAddEquipment = () => {
        if (checkCanSave() != null) {
            setErrorMessage(checkCanSave());
            setOpenErrorSnackbar(true);
        }
        else {

            if (equipmentToDeliver) {
                setEquipmentToDeliver([...equipmentToDeliver, { ...formData, id: id }])
            } else {
                setEquipmentToDeliver([{ ...formData, id: id }])
            }
            setId(id + 1);
            switch (action) {
                case 'Delivery':
                    setFormData({ ...formData, equipment: '', quantity: '', dept: '' });
                    break;
                case 'Collection':
                    setFormData({ ...formData, equipment: '', quantity: '', action: '' });
                    break;
                default:
                    setFormData({ ...formData, equipment: '', quantity: '', palletsOrCages: '', newOrUsed: '' });
                    break;
            }
        }
    }

    const handleDeleteEquipment = (itemToDel) => {
        const equipmentToDeliverCopy = equipmentToDeliver.filter(item => item.id !== itemToDel.id)
        setEquipmentToDeliver(equipmentToDeliverCopy)
    }

    const handleEditEquipment = (itemToEdit) => {
        setFormData(itemToEdit)
        setIsEditing(true)
    }

    const handleConfirmEdit = () => {
        const index = equipmentToDeliver.findIndex(item => item.id === formData.id)
        const equipmentToDeliverCopy = [...equipmentToDeliver]
        equipmentToDeliverCopy[index] = formData
        setEquipmentToDeliver(equipmentToDeliverCopy)
        setIsEditing(false)
        switch (action) {
            case 'Delivery':
                setFormData({ ...formData, equipment: '', quantity: '', dept: '' });
                break;
            case 'Collection':
                setFormData({ ...formData, equipment: '', quantity: '', action: '' });
                break;
            default:
                setFormData({ ...formData, equipment: '', quantity: '', palletsOrCages: '', newOrUsed: '' });
                break;
        }
    }

    const handleResetInputs = () => {
        switch (action) {
            case 'Delivery':
                setFormData({ ...formData, equipment: '', quantity: '', dept: '' });
                break;
            case 'Collection':
                setFormData({ ...formData, equipment: '', quantity: '', action: '' });
                break;
            default:
                setFormData({ ...formData, equipment: '', quantity: '', palletsOrCages: '', newOrUsed: '' });
                break;
        }
    }


    const handleCancelEdit = () => {
        let formDataMod = { ...formData }
        delete formDataMod.id;
        setFormData(formDataMod)
        setIsEditing(false)
    }


    const checkCanSave = () => {
        if (formData.equipment === '' ||
            formData.quantity === '' ||
            (action === 'Delivery' && formData.dept === '') ||
            (action === 'Collection' && formData.action === '') ||
            ((action === 'TSL ERC Collection (for return and reuse)' || action === 'SSL ERC Collection (for return and reuse)') && (formData.palletsOrCages === '' || formData.newOrUsed === '')))
            return "Please complete all fields to add equipment.";
        if (Number(formData.quantity) === 0)
            return "The quantity cannot be 0."
        if (Number(formData.quantity) <= 0)
            return "Quantity should be a positive number";
        if (formData.equipment.length > 0 && formData.equipment.replace(/\s/g, '').length === 0)
            return "Please insert the name of the equipment."
        return null;
    }

    const handleCloseSnackbar = () => {
        setOpenErrorSnackbar(false);
    };

    return (
        <Box>
            {action !== null ?
                <Box>

                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Autocomplete
                            name='equipmentDescription'
                            disablePortal
                            freeSolo
                            size='small'
                            options={equipmentParts ? equipmentParts : []}
                            sx={{ width: 300 }}
                            value={formData.equipment}
                            onInputChange={handleAutocompleteChanged}
                            onChange={handleAutocompleteChanged}
                            renderInput={(params) => <TextField variant='standard' required {...params} label="Equipment Description" />
                            }
                        />
                        <TextField type='number' autoComplete='off' variant='standard' size='small' name='quantity' label='Quantity'
                            value={formData.quantity ? formData.quantity : ''} required
                            onChange={handleInputChange} />

                        {action === "Delivery" ? <SelectInput name='dept' label='Dept *' value={formData.dept} options={deptOptions} handleChange={handleSelectChanged} variant='standard' /> : null}
                        {action === "Collection" || action === 'Site Collection' || action === 'Plant Collection' ? <SelectInput name='action' label='Action *' value={formData.action} options={actionOptions} handleChange={handleSelectChanged} variant='standard' /> : null}
                        {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <SelectInput name='palletsOrCages' label='Pallets or Cages *' value={formData.palletsOrCages} options={palletsOrCagesOptions} handleChange={handleSelectChanged} variant='standard' /> : null}
                        {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <SelectInput name='newOrUsed' label='New or Used *' value={formData.newOrUsed} options={newOrUsedOptions} handleChange={handleSelectChanged} variant='standard' /> : null}

                        {!isEditing && <Button size='small' variant='contained' color='primary' onClick={handleAddEquipment}>ADD EQUIPMENT</Button>}
                        {!isEditing && <Button size='small' variant='contained' color='error' onClick={handleResetInputs} >RESET INPUTS</Button>}
                        {isEditing && <Button size='small' variant='contained' color='primary' onClick={handleConfirmEdit}>CONFIRM EDIT</Button>}
                        {isEditing && <Button size='small' variant='contained' color='error' onClick={handleCancelEdit}>CANCEL EDIT</Button>}
                    </Stack>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Equipment</TableCell>
                                    <TableCell align='left'>Qty</TableCell>
                                    {action === 'Delivery' ? <TableCell align='left'>Dept</TableCell> : null}
                                    {action === 'Collection' || action === 'Site Collection' || action === 'Plant Collection' ? <TableCell align='left'>Action</TableCell> : null}
                                    {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <TableCell align='left'>Pallets Or Cages</TableCell> : null}
                                    {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <TableCell align='left'>New Or Used</TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {equipmentToDeliver && equipmentToDeliver.map((equipment, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }} hover>
                                        <TableCell align='left'>{equipment.equipment}</TableCell>
                                        <TableCell align='left'>{equipment.quantity}</TableCell>
                                        {action === 'Delivery' ? <TableCell align='left'>{equipment.dept}</TableCell> : null}
                                        {action === 'Collection' || action === 'Site Collection' || action === 'Plant Collection' ? <TableCell align='left'>{equipment.action}</TableCell> : null}
                                        {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <TableCell align='left'>{equipment.palletsOrCages}</TableCell> : null}
                                        {action.indexOf('TSL') > -1 || action.indexOf('SSL') > -1 ? <TableCell align='left'>{equipment.newOrUsed}</TableCell> : null}

                                        <TableCell align="center">
                                            <IconButton size='small' onClick={() => { handleEditEquipment(equipment) }} aria-label="edit">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton size='small' onClick={() => { handleDeleteEquipment(equipment) }} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Box> : null}
        </Box>
    )
}



export const addAddressCommaToString = (str, isLast = false) => {
    if (str === null || str === '' || (str.length > 0 && str.replace(/\s/g, '').length === 0)) {
        return '';
    }
    if (isLast) {
        return str;
    }
    return str + ', ';
}

export const addAddressComma = (str) => {
    if (str === null || str === '' || (str.length > 0 && str.replace(/\s/g, '').length === 0)) {
        return '';
    }
    return ', ';
}

export default TransportRequisitionsForm


